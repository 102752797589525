import Battery0BarIcon from '@mui/icons-material/Battery0Bar'
import DeleteIcon from '@mui/icons-material/Delete'
import GTranslateIcon from '@mui/icons-material/GTranslate'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import {
  Divider,
  IconButton,
  Link,
  Menu,
  MenuItem,
  styled,
} from '@mui/material'
import { observer } from 'mobx-react-lite'
import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  deleteManyWords,
  updateWordsDictionary,
  updateWordsTrainingLevel,
} from 'src/api/requests/words'
import BtnWithConfirm from 'src/components/widgets/BtnWithConfirm'
import MoveBtnWithDictionarySelect from 'src/components/widgets/MoveBtnWithDictionarySelect'
import DictionaryStore from 'src/mobx/dictionary'
import { IWord } from 'src/types/Word'
import { getGoogleWordTranslateUrl } from 'src/utils/getGoogleWordTranslateUrl'
import { getOxfordWordUrl } from 'src/utils/getOxfordWordUrl'
import { getYandexWordTranslateUrl } from 'src/utils/getYandexWordTranslateUrl'

const StyledLink = styled(Link)(() => ({
  padding: '7px 10px',
  display: 'flex',
  gap: '5px',
  alignItems: 'center',
  transition: '.2s',
  cursor: 'pointer',
  color: 'black',
  textDecoration: 'none',
}))

interface IProps {
  wordItem: IWord
}

const WordMoreMenu = observer(({ wordItem }: IProps) => {
  const { t } = useTranslation(['dictionaryPage', 'common'])

  const ref = useRef(null)
  const [isOpen, setIsOpen] = useState(false)
  const { id, word } = wordItem

  const onDelete = async () => {
    await deleteManyWords([id]).then(() => {
      DictionaryStore.removeWordsById([id])
    })
  }

  const onMove = async (dictionaryId: number) => {
    await updateWordsDictionary({ wordIds: [id], dictionaryId }).then(() => {
      DictionaryStore.removeWordsById([id])
    })
  }

  const onUnchargeWord = async () => {
    await updateWordsTrainingLevel([
      {
        id: wordItem.id,
        dictionaryId: wordItem.dictionaryId,
        trainingLevel: 0,
      },
    ]).then(() => {
      DictionaryStore.updateWord({ ...wordItem, trainingLevel: 0 })
      setIsOpen(false)
    })
  }

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <MoreVertIcon />
      </IconButton>

      {DictionaryStore.selectedDictionary && (
        <Menu
          open={isOpen}
          anchorEl={ref.current}
          onClose={() => setIsOpen(false)}
          PaperProps={{
            sx: { width: 200, maxWidth: '100%' },
          }}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <MenuItem sx={{ color: 'text.secondary', padding: 0 }}>
            <StyledLink
              href={getGoogleWordTranslateUrl(
                word,
                DictionaryStore.selectedDictionary.fromLanguage.code,
                DictionaryStore.selectedDictionary.toLanguage.code,
              )}
              target="_blank"
              rel="noreferrer"
            >
              <GTranslateIcon />
              Google translate
            </StyledLink>
          </MenuItem>
          <MenuItem sx={{ color: 'text.secondary', padding: 0 }}>
            <StyledLink
              href={getYandexWordTranslateUrl(
                word,
                DictionaryStore.selectedDictionary.fromLanguage.code,
                DictionaryStore.selectedDictionary.toLanguage.code,
              )}
              target="_blank"
              rel="noreferrer"
            >
              <img src="/static/icons/yandex_translate.png" height={25} />
              Yandex translate
            </StyledLink>
          </MenuItem>
          {DictionaryStore.selectedDictionary.fromLanguage.code === 'en' &&
            word.indexOf(' ') === -1 && (
              <MenuItem sx={{ color: 'text.secondary', padding: 0 }}>
                <StyledLink
                  href={getOxfordWordUrl(word)}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src="/static/icons/oxford_dictionary.png" height={25} />
                  Oxford Dictionary
                </StyledLink>
              </MenuItem>
            )}

          <Divider />

          {DictionaryStore.dictionaries && DictionaryStore.selectedDictionary && (
            <MenuItem
              sx={{ color: 'text.secondary', padding: 0, display: 'flex' }}
            >
              <MoveBtnWithDictionarySelect
                onMove={onMove}
                dictionaries={DictionaryStore.dictionaries}
                selectedDictionary={DictionaryStore.selectedDictionary}
                btnText={t('move', { ns: 'common' })}
              />
            </MenuItem>
          )}

          {wordItem.trainingLevel > 0 && (
            <MenuItem
              sx={{ color: 'text.secondary', padding: 0, display: 'flex' }}
            >
              <BtnWithConfirm
                icon={Battery0BarIcon}
                onConfirm={onUnchargeWord}
                modalTitle={t('are_you_sure', { ns: 'common' })}
                btnText={t('uncharge_word')}
                iconColorOnHover="error"
              />
            </MenuItem>
          )}

          <MenuItem
            sx={{ color: 'text.secondary', padding: 0, display: 'flex' }}
          >
            <BtnWithConfirm
              icon={DeleteIcon}
              onConfirm={onDelete}
              modalTitle={`${t('delete_word')} "${word}".`}
              btnText={t('delete', { ns: 'common' })}
              iconColorOnHover="error"
            />
          </MenuItem>
        </Menu>
      )}
    </>
  )
})

export default WordMoreMenu
