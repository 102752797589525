import CloseIcon from '@mui/icons-material/Close'
import { Box, IconButton, Modal, Stack, Typography } from '@mui/material'
import { IWord } from 'src/types/Word'

import WordForms from './WordForms'
import WordUsageExample from './WordUsageExample'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  height: '99%',
  maxWidth: 850,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: {
    sm: 4,
    xs: 1,
  },
  overflowY: 'auto',
}

interface IProps {
  isOpen: boolean
  onClose: () => void
  openedWord: IWord
  setOpenedWord: (word: IWord) => void
}

const PublicDictionaryWordModal = ({
  isOpen,
  onClose,
  openedWord,
  setOpenedWord,
}: IProps) => {
  const onModalClose = () => {
    onClose()
  }

  return (
    <Modal open={isOpen} onClose={onModalClose} sx={{ margin: '0 10px' }}>
      <Box sx={style}>
        <Stack padding="10px 0" position="relative">
          <Box
            sx={{
              position: 'absolute',
              zIndex: 100,
              top: {
                sm: '-23px',
                xs: '0',
              },
              right: {
                sm: '-20px',
                xs: '-4px',
              },
            }}
            onClick={() => onModalClose()}
          >
            <IconButton>
              <CloseIcon />
            </IconButton>
          </Box>

          <Typography
            sx={{
              textAlign: 'center',
              position: 'relative',
              fontSize: {
                md: '40px',
                xs: '20px',
              },
              textTransform: 'uppercase',
              fontWeight: 600,
            }}
          >
            {openedWord.word}
          </Typography>

          {openedWord.transcription && (
            <Typography
              textAlign="center"
              color="#b5b5b5"
              fontSize={{
                md: '19px',
                xs: '14px',
              }}
            >
              {openedWord.transcription}
            </Typography>
          )}
          <Typography
            marginTop={3}
            textAlign="center"
            fontSize={{
              md: '27px',
              xs: '19px',
            }}
          >
            {openedWord.translation}
          </Typography>
        </Stack>

        <Stack>
          <WordForms openedWord={openedWord} setOpenedWord={setOpenedWord} />

          <WordUsageExample
            openedWord={openedWord}
            setOpenedWord={setOpenedWord}
          />
        </Stack>
      </Box>
    </Modal>
  )
}

export default PublicDictionaryWordModal
