import CancelIcon from '@mui/icons-material/Cancel'
import CloseIcon from '@mui/icons-material/Close'
import EditIcon from '@mui/icons-material/Edit'
import { Box, IconButton, Modal, Stack, Typography } from '@mui/material'
import { useState } from 'react'
import EditWordForm from 'src/components/forms/EditWordForm'
import { IWord } from 'src/types/Word'

import WordForms from './WordForms'
import WordUsageExample from './WordUsageExample'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  height: '99%',
  maxWidth: 850,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: {
    sm: 4,
    xs: 1,
  },
  overflowY: 'auto',
}

interface IProps {
  isOpen: boolean
  onClose: () => void
  openedWord: IWord
  setOpenedWord: (word: IWord) => void
  onSubmit: (
    editedWord: IWord,
    setError: (message: string) => void,
  ) => Promise<void>
}

const WordModal = ({
  isOpen,
  onClose,
  openedWord,
  onSubmit,
  setOpenedWord,
}: IProps) => {
  const [wordEdit, setWordEdit] = useState(false)
  const [somethingIsEditing, setSomethingIsEditing] = useState(false)

  const toggleEdit = () =>
    setWordEdit((prev) => {
      setSomethingIsEditing(!prev)
      return !prev
    })
  const closeEdit = () => {
    setWordEdit(false)
    setSomethingIsEditing(false)
  }

  const onModalClose = () => {
    setSomethingIsEditing(false)
    setWordEdit(false)
    onClose()
  }

  return (
    <Modal open={isOpen} onClose={onModalClose} sx={{ margin: '0 10px' }}>
      <Box sx={style}>
        <Stack padding="10px 0" position="relative">
          <Box
            sx={{
              position: 'absolute',
              zIndex: 100,
              top: {
                sm: '-23px',
                xs: '0',
              },
              right: {
                sm: '-20px',
                xs: '-4px',
              },
            }}
            onClick={() => onModalClose()}
          >
            <IconButton>
              <CloseIcon />
            </IconButton>
          </Box>

          <Typography
            sx={{
              textAlign: 'center',
              position: 'relative',
              fontSize: {
                md: '40px',
                xs: '20px',
              },
              textTransform: 'uppercase',
              fontWeight: 600,
            }}
          >
            {openedWord.word}
          </Typography>

          <Box position="relative">
            {!(somethingIsEditing && !wordEdit) && (
              <Box
                position="absolute"
                right={{
                  sm: wordEdit ? '-35px' : '-20px',
                  xs: '-5px',
                }}
              >
                <IconButton
                  onClick={toggleEdit}
                  title={wordEdit ? 'Undo editiong' : 'Edit'}
                >
                  {wordEdit ? <CancelIcon /> : <EditIcon />}
                </IconButton>
              </Box>
            )}
          </Box>

          {!wordEdit ? (
            <>
              {openedWord.transcription && (
                <Typography
                  textAlign="center"
                  color="#b5b5b5"
                  fontSize={{
                    md: '19px',
                    xs: '14px',
                  }}
                >
                  {openedWord.transcription}
                </Typography>
              )}
              <Typography
                marginTop={3}
                textAlign="center"
                fontSize={{
                  md: '27px',
                  xs: '19px',
                }}
              >
                {openedWord.translation}
              </Typography>
            </>
          ) : (
            <EditWordForm
              editedWord={openedWord}
              closeEdit={closeEdit}
              onSubmit={onSubmit}
            />
          )}
        </Stack>

        <Stack>
          <WordForms
            openedWord={openedWord}
            setOpenedWord={setOpenedWord}
            somethingIsEditing={somethingIsEditing}
            setSomethingIsEditing={setSomethingIsEditing}
          />

          <WordUsageExample
            openedWord={openedWord}
            setOpenedWord={setOpenedWord}
            somethingIsEditing={somethingIsEditing}
            setSomethingIsEditing={setSomethingIsEditing}
          />
        </Stack>
      </Box>
    </Modal>
  )
}

export default WordModal
