import { Box, Card, Container, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { patchStartRestoration } from 'src/api/requests/users'
import EmailForm from 'src/components/forms/EmailForm'
import Logo from 'src/components/Logo'
import Page from 'src/components/Page'
import useResponsive from 'src/hooks/useResponsive'
import { getError } from 'src/utils/getError'

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}))

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7),
  },
}))

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
}))

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}))

const StartPasswordRestoration = observer(() => {
  const [congratulation, setCongratulation] = useState<boolean>(false)

  const { t } = useTranslation(['restorePasswordPage'])

  const mdUp = useResponsive('up', 'md')

  const onSubmit = (
    value: { email: string },
    setError: (message: string) => void,
  ): Promise<void> => {
    return patchStartRestoration(value)
      .then(() => {
        setCongratulation(true)
      })
      .catch((err) => {
        setError(getError(err).message)
      })
  }

  return (
    <Page title="Password Restoration">
      <Box>
        <RootStyle>
          <HeaderStyle>
            <Logo />
          </HeaderStyle>

          {mdUp && (
            <SectionStyle>
              <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
                {t('title')}
              </Typography>
              <img
                src="/static/illustrations/illustration_login.png"
                alt="login"
              />
            </SectionStyle>
          )}
          {congratulation ? (
            <Container>
              <ContentStyle>
                <Typography variant="h4" gutterBottom>
                  {t('toEmail')}
                </Typography>
              </ContentStyle>
            </Container>
          ) : (
            <Container>
              <ContentStyle>
                <Typography variant="h5" sx={{ mb: 4 }}>
                  {t('yourEmail')}
                </Typography>
                <EmailForm onSubmit={onSubmit} />
              </ContentStyle>
            </Container>
          )}
        </RootStyle>
      </Box>
    </Page>
  )
})

export default StartPasswordRestoration
