import { LoadingButton } from '@mui/lab'
import { Alert, Stack, TextField } from '@mui/material'
import { Form, FormikProvider, useFormik } from 'formik'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IWord } from 'src/types/Word'
import * as Yup from 'yup'

interface IProps {
  editedWord: IWord
  closeEdit: () => void
  onSubmit: (
    editedWord: IWord,
    setError: (message: string) => void,
  ) => Promise<void>
}

const EditWordForm = ({ editedWord, closeEdit, onSubmit }: IProps) => {
  const [error, setError] = useState<string | null>(null)

  const { t } = useTranslation(['common', 'error'])

  const Schema = Yup.object().shape({
    translation: Yup.string().min(1).max(300).required(),
    transcription: Yup.string().min(1).max(300).optional(),
  })

  const formik = useFormik({
    initialValues: {
      translation: editedWord.translation,
      transcription: editedWord.transcription || '',
    },
    validationSchema: Schema,
    onSubmit: () => {
      onSubmit(
        {
          ...editedWord,
          translation: values.translation,
          transcription: values.transcription,
        },
        setError,
      )
        .then(() => closeEdit())
        .finally(() => setSubmitting(false))
    },
  })

  const {
    errors,
    touched,
    values,
    isSubmitting,
    setSubmitting,
    handleSubmit,
    getFieldProps,
  } = formik

  return (
    <FormikProvider value={formik}>
      <Form
        style={{ minWidth: '150px' }}
        autoComplete="off"
        noValidate
        onSubmit={handleSubmit}
      >
        <Stack spacing={{ sm: 3, xs: 1 }} mr={{ sm: 0, xs: '40px' }} mt={2}>
          <TextField
            fullWidth
            type="text"
            label={t('transcription')}
            error={Boolean(touched.transcription && errors.transcription)}
            helperText={touched.transcription && errors.transcription}
            {...getFieldProps('transcription')}
          />
        </Stack>

        <Stack marginTop={{ sm: 3, xs: 1 }}>
          <TextField
            fullWidth
            type="text"
            label={t('translation')}
            error={Boolean(touched.translation && errors.translation)}
            helperText={touched.translation && errors.translation}
            {...getFieldProps('translation')}
          />
        </Stack>

        {error && !isSubmitting && (
          <Alert sx={{ mt: { sm: 4, xs: 2 } }} severity="error">
            {error}
          </Alert>
        )}

        <LoadingButton
          sx={{ mt: { sm: 4, xs: 2 } }}
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
        >
          {t('confirm')}
        </LoadingButton>
      </Form>
    </FormikProvider>
  )
}

export default EditWordForm
