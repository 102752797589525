import loadable from '@loadable/component'
import { Navigate, useRoutes } from 'react-router-dom'
import UserStore from 'src/mobx/user'

import DashboardLayout from './layouts/dashboard'
import LogoOnlyLayout from './layouts/LogoOnlyLayout'
import ProtectedRoute from './layouts/ProtectedRoute'
import NotFound from './pages/404'
import Activate from './pages/Activate'
import Dictionary from './pages/dashboard/Dictionary'
import Workouts from './pages/dashboard/workouts'
import IRemember from './pages/dashboard/workouts/iRemember'
import FinishPasswordRestoration from './pages/FinishPasswordRestoration'
import Login from './pages/Login'
import PublicDictionary from './pages/PublicDictionary'
import Register from './pages/Register'
import StartPasswordRestoration from './pages/StartPasswordRestoration'
import Profile from './pages/user/Profile'
import Settings from './pages/user/Settings'
import {
  PATH_ACTIVATE,
  PATH_DASHBOARD,
  PATH_DICTIONARY,
  PATH_I_REMEMBER,
  PATH_LETTER_HAMMER,
  PATH_LOGIN,
  PATH_NOTFOUND,
  PATH_PROFILE,
  PATH_REGISTER,
  PATH_RESTORE_PASSWORD,
  PATH_SETTINGS,
  PATH_USER,
  PATH_WORKOUT,
  ROUTE_DICTIONARY,
  ROUTE_PUBLIC_DICTIONARY,
} from './resources/routes'

const LetterHammer = loadable(
  () => import('src/pages/dashboard/workouts/LetterHammer'),
)

export default function Router() {
  return useRoutes([
    {
      path: PATH_DASHBOARD,
      element: (
        <ProtectedRoute isAllowed={!!UserStore.user}>
          <DashboardLayout />
        </ProtectedRoute>
      ),
      children: [
        { path: PATH_DICTIONARY, element: <Dictionary /> },
        {
          path: PATH_WORKOUT,
          children: [
            { path: '', element: <Workouts /> },
            { path: PATH_I_REMEMBER, element: <IRemember /> },
            {
              path: PATH_LETTER_HAMMER,
              element: <LetterHammer />,
            },
          ],
        },
        {
          path: PATH_USER,
          children: [
            { path: PATH_PROFILE, element: <Profile /> },
            { path: PATH_SETTINGS, element: <Settings /> },
          ],
        },
      ],
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: '/', element: <Navigate to={ROUTE_DICTIONARY} /> },
        {
          path: PATH_LOGIN,
          element: <Login />,
        },
        { path: PATH_REGISTER, element: <Register /> },
        {
          path: `${PATH_ACTIVATE}/`,
          element: <Activate />,
          children: [{ path: ':link', element: <Activate /> }],
        },
        {
          path: PATH_RESTORE_PASSWORD,
          element: <StartPasswordRestoration />,
        },
        {
          path: `${PATH_RESTORE_PASSWORD}/:link`,
          element: <FinishPasswordRestoration />,
        },
        { path: PATH_NOTFOUND, element: <NotFound /> },
        { path: '*', element: <Navigate to={PATH_NOTFOUND} /> },
      ],
    },
    {
      path: `${ROUTE_PUBLIC_DICTIONARY}/:dictionaryId`,
      element: <PublicDictionary />,
    },
    { path: '*', element: <Navigate to={PATH_NOTFOUND} replace /> },
  ])
}
