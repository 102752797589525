import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import { Box, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { PATH_DASHBOARD, PATH_WORKOUT } from 'src/resources/routes'

const RememberFinishScreen = React.forwardRef<HTMLAnchorElement>(
  (props, ref) => {
    const { t } = useTranslation(['workoutPages'])

    return (
      <Box>
        <Typography variant="body1" textAlign="center">
          {t('training_is_over')}
        </Typography>
        <Box textAlign="center" mt="20px">
          <Link
            style={{
              fontSize: '20px',
              textDecoration: 'none',
              backgroundColor: '#38b098',
              padding: '8px 15px',
              borderRadius: '7px',
              color: 'white',
            }}
            ref={ref}
            to={`/${PATH_DASHBOARD}/${PATH_WORKOUT}`}
          >
            <Box component="span">
              {t('back_to_workouts')}
              <Box
                component="span"
                display={{ md: 'inline-block', xs: 'none' }}
                style={{
                  marginLeft: '10px',
                  transform: 'rotate(-90deg)',
                }}
              >
                <KeyboardBackspaceIcon />
              </Box>
            </Box>
          </Link>
        </Box>
      </Box>
    )
  },
)

export default RememberFinishScreen
