import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import HelpIcon from '@mui/icons-material/Help'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Tooltip,
  Typography,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getPublicWordForms } from 'src/api/requests/wordForms'
import LoadingContainer from 'src/components/common/LoadingContainer'
import { IWord } from 'src/types/Word'

import { WordFormItem } from './WordFormItem'

interface IProps {
  openedWord: IWord
  setOpenedWord: (w: IWord) => void
}

const WordForms = ({ openedWord, setOpenedWord }: IProps) => {
  const { t } = useTranslation(['dictionaryPage', 'common'])
  const [formsLoading, setFormsLoading] = useState(false)
  const [expanded, setExpanded] = useState<boolean>(false)

  useEffect(() => {
    if (!openedWord.forms && expanded) {
      setFormsLoading(true)
      getPublicWordForms(openedWord.id)
        .then((res) => {
          const sortedFormList = res.wordForms.sort(
            (a, b) =>
              new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
          )
          const updatedWord = {
            ...openedWord,
            forms: sortedFormList,
          }

          setOpenedWord(updatedWord)
        })
        .finally(() => {
          setFormsLoading(false)
        })
    }
  }, [openedWord, expanded])

  const toggleExpanded = () => setExpanded((prev) => !prev)

  return (
    <Accordion sx={{ border: '1px solid #ededed' }} onChange={toggleExpanded}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={{ backgroundColor: '#f5f5f5' }}
      >
        <Box display="flex" gap="10px">
          <Typography color="gray">{t('word_forms')}</Typography>
          <Box position="relative">
            <Box position="absolute">
              <Tooltip title={t('what_is_word_form')} placement="top">
                <Typography>
                  <HelpIcon color="disabled" />
                </Typography>
              </Tooltip>
            </Box>
          </Box>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        {!formsLoading ? (
          <Box>
            {openedWord.forms && (
              <Box>
                {openedWord.forms.length !== 0 ? (
                  openedWord.forms.map((wordForm) => (
                    <WordFormItem key={wordForm.id} wordForm={wordForm} />
                  ))
                ) : (
                  <Typography>{t('no_results', { ns: 'common' })}</Typography>
                )}
              </Box>
            )}
          </Box>
        ) : (
          <LoadingContainer />
        )}
      </AccordionDetails>
    </Accordion>
  )
}

export default WordForms
