import { TableBody, TableCell, TableRow } from '@mui/material'
import { observer } from 'mobx-react-lite'
import React from 'react'
import DictionaryStore from 'src/mobx/dictionary'
import { IWord } from 'src/types/Word'

import WordRow from './WordRow'

interface IDictionaryBuilderBody {
  id: number
  name: string
  words: IWord[]
}

interface IProps {
  selected: number[]
  setSelected: (a: number[]) => void
  handleOpenWordModal: (w: IWord) => void
}

const DictionaryWordList = observer(
  ({ selected, setSelected, handleOpenWordModal }: IProps) => {
    const showTranscription = DictionaryStore.selectedDictionary
      ? DictionaryStore.selectedDictionary.showTranscription
      : true
    return (
      <>
        <TableBody>
          {DictionaryStore.selectedDictionaryWords.map((item) => (
            <WordRow
              key={item.id}
              item={item}
              selected={selected}
              setSelected={setSelected}
              handleOpenWordModal={handleOpenWordModal}
              showTranscription={showTranscription}
            />
          ))}
        </TableBody>
        {(() => {
          const dictionaries: IDictionaryBuilderBody[] = []
          DictionaryStore.otherDictionaryWords.forEach((item) => {
            const existingDictionaryIndex = dictionaries.findIndex(
              (d) => d.id === item.dictionaryId,
            )

            if (existingDictionaryIndex === -1) {
              const dictionaryName =
                DictionaryStore.dictionaries &&
                DictionaryStore.dictionaries.find(
                  (d) => d.id === item.dictionaryId,
                )?.title
              dictionaries.push({
                id: item.dictionaryId,
                name: dictionaryName || '',
                words: [item],
              })
            } else {
              dictionaries[existingDictionaryIndex].words.push(item)
            }
          })

          return (
            <>
              {dictionaries.map((d) => (
                <TableBody key={d.id}>
                  <TableRow>
                    <TableCell
                      style={{
                        background: '#f7f7f7',
                        paddingTop: '3px',
                        paddingBottom: '3px',
                        color: 'darkgray',
                        fontWeight: 'bold',
                      }}
                      colSpan={10}
                    >
                      {d.name}
                    </TableCell>
                  </TableRow>
                  {d.words.map((item) => (
                    <WordRow
                      key={item.id}
                      item={item}
                      selected={selected}
                      setSelected={setSelected}
                      handleOpenWordModal={handleOpenWordModal}
                      showTranscription={showTranscription}
                    />
                  ))}
                </TableBody>
              ))}
            </>
          )
        })()}
      </>
    )
  },
)

export default DictionaryWordList
