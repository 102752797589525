import Battery0BarIcon from '@mui/icons-material/Battery0Bar'
import Battery30Icon from '@mui/icons-material/Battery30'
import Battery60Icon from '@mui/icons-material/Battery60'
import Battery90Icon from '@mui/icons-material/Battery90'
import BatteryFullIcon from '@mui/icons-material/BatteryFull'
import { Box } from '@mui/material'
import React from 'react'

interface IProps {
  title: string
  chargeLevel: number
}

const ChargeLevel = ({ title, chargeLevel }: IProps) => {
  const IconComponent = () => {
    const roundedNumber = Math.floor(chargeLevel)
    let Component
    switch (roundedNumber) {
      case 0:
        Component = <Battery0BarIcon color="error" />
        break
      case 1:
        Component = <Battery30Icon color="warning" />
        break
      case 2:
        Component = <Battery60Icon color="secondary" />
        break
      case 3:
        Component = <Battery90Icon color="primary" />
        break
      default:
        Component = <BatteryFullIcon color="success" />
        break
    }

    return Component
  }

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }} title={title}>
      <IconComponent />
    </Box>
  )
}

export default ChargeLevel
