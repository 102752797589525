import { Card, Container, Link, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Link as RouterLink } from 'react-router-dom'
import AuthSocial from 'src/components/forms/AuthSocial'

import Logo from '../components/Logo'
import Page from '../components/Page'
import useResponsive from '../hooks/useResponsive'
import { RegisterForm } from '../sections/auth/register'

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}))

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7),
  },
}))

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
}))

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}))

export default function Register() {
  const { t } = useTranslation('registerPage')

  const smUp = useResponsive('up', 'sm')
  const mdUp = useResponsive('up', 'md')

  const [congratulation, setCongratulation] = useState(false)

  const navigate = useNavigate()

  return (
    <Page title={t('register')}>
      <RootStyle>
        <HeaderStyle>
          <Logo />
          {smUp && (
            <Typography variant="body2" sx={{ mt: { md: -2 } }}>
              {t('have_account')} {''}
              <Link variant="subtitle2" component={RouterLink} to="/login">
                {t('login')}
              </Link>
            </Typography>
          )}
        </HeaderStyle>

        {mdUp && (
          <SectionStyle>
            <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
              {t('title')}
            </Typography>
            <img
              alt="register"
              src="/static/illustrations/illustration_register.png"
            />
          </SectionStyle>
        )}

        {congratulation ? (
          <Container>
            <ContentStyle>
              {t('congratulation')}
              <span
                onClick={() => navigate('/', { replace: true })}
                style={{ cursor: 'pointer', fontWeight: '700' }}
              >
                {t('go_home')}
              </span>
            </ContentStyle>
          </Container>
        ) : (
          <Container>
            <ContentStyle>
              <Typography variant="h4" gutterBottom>
                {t('get_started')}
              </Typography>

              <Typography sx={{ color: 'text.secondary', mb: 5 }}>
                {t('free_forever')}
              </Typography>

              <AuthSocial />

              <RegisterForm setCongratulation={setCongratulation} />

              {!smUp && (
                <Typography variant="body2" sx={{ mt: 3, textAlign: 'center' }}>
                  {t('have_account')}{' '}
                  <Link variant="subtitle2" to="/login" component={RouterLink}>
                    {t('login')}
                  </Link>
                </Typography>
              )}
            </ContentStyle>
          </Container>
        )}
      </RootStyle>
    </Page>
  )
}
