import { IWordForm } from 'src/types/WordForm'

import { client } from '../axios'
import { ApiRoutes } from '../routes'
import { ISuccess } from './../types/success'

export interface IWordFormsResponse {
  wordForms: IWordForm[]
}

export interface IWordFormResponse {
  wordForm: IWordForm
}

// GET (all)
export const getWordForms = async (
  wordId: number,
): Promise<IWordFormsResponse> => {
  return (await client.get(ApiRoutes.getWordForms(wordId))).data
}

// GET Public (all)
export const getPublicWordForms = async (
  wordId: number,
): Promise<IWordFormsResponse> => {
  return (await client.get(ApiRoutes.getPublicWordForms(wordId))).data
}

// POST (one)
export const postWordForm = async (
  body: Omit<IWordForm, 'id' | 'createdAt'>,
): Promise<IWordFormResponse> => {
  return (
    await client.post(ApiRoutes.postWordForm, {
      wordForm: body,
    })
  ).data
}

// PUT (one)
export const updateWordForm = async (
  id: number,
  body: Omit<IWordForm, 'id' | 'createdAt'>,
): Promise<IWordFormResponse> => {
  return (
    await client.put(ApiRoutes.updateWordForm(id), {
      wordForm: body,
    })
  ).data
}

// Delete (many)
export const deleteManyWordForms = async (
  wordId: number,
  wordFormIds: number[],
): Promise<ISuccess> => {
  return (
    await client.delete(ApiRoutes.deleteManyWordForms(wordId), {
      data: {
        wordFormIds,
      },
    })
  ).data
}
