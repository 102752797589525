import { styled } from '@mui/material/styles'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import DictionaryStore from 'src/mobx/dictionary'
import UIStore from 'src/mobx/ui'

import DashboardNavbar from './DashboardNavbar'
import DashboardSidebar from './DashboardSidebar'

const APP_BAR_MOBILE = 64
const APP_BAR_DESKTOP = 92

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
})

const MainStyle: any = styled('div')(({ theme, isMinimized }: any) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: isMinimized ? 8 : APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: isMinimized ? 16 : APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}))

const DashboardLayout = observer(() => {
  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (DictionaryStore.dictionaries === null) DictionaryStore.getDictionaries()
  }, [])

  return (
    <RootStyle>
      <DashboardNavbar onOpenSidebar={() => setOpen(true)} />
      <DashboardSidebar
        isOpenSidebar={open}
        onCloseSidebar={() => setOpen(false)}
      />
      <MainStyle isMinimized={UIStore.isHeaderMinimized}>
        <Outlet />
      </MainStyle>
    </RootStyle>
  )
})

export default DashboardLayout
