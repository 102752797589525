// import FacebookIcon from '@mui/icons-material/Facebook'
// import TwitterIcon from '@mui/icons-material/Twitter'
import { Alert, Box, Divider, Typography } from '@mui/material'
import {
  CredentialResponse,
  GoogleLogin,
  GoogleOAuthProvider,
} from '@react-oauth/google'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { authGoogle } from 'src/api/requests/auth'
import UserStore from 'src/mobx/user'
import { ACCESS_TOKEN_STORE_KEY } from 'src/resources/localStorageKeys'
import { ROUTE_DICTIONARY } from 'src/resources/routes'
import { getError } from 'src/utils/getError'

import LoadingContainer from '../common/LoadingContainer'

const AuthSocial = observer(() => {
  const { t, i18n } = useTranslation('registerPage')
  const navigate = useNavigate()

  const [authLoading, setAuthLoading] = useState(false)
  const [authError, setAuthError] = useState<string | null>(null)

  const onGoogleSuccess = async (res: CredentialResponse) => {
    if (res.credential) {
      setAuthLoading(true)

      await authGoogle(res.credential)
        .then((data) => {
          localStorage.setItem(ACCESS_TOKEN_STORE_KEY, data.accessToken)
          UserStore.setUser(data.user)
          setAuthError(null)
          navigate(ROUTE_DICTIONARY, { replace: true })
        })
        .catch((err) => {
          setAuthError(getError(err).message)
        })
        .finally(() => {
          setAuthLoading(false)
        })
    }
  }
  const onGoogleError = () => {
    setAuthError('Error with Google Auth')
    setAuthLoading(false)
  }

  return (
    <>
      <Box display="flex" justifyItems="center">
        {authLoading ? (
          <LoadingContainer size="small" />
        ) : (
          <Box marginX="auto">
            <GoogleOAuthProvider
              clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID as string}
            >
              <GoogleLogin
                onSuccess={onGoogleSuccess}
                onError={onGoogleError}
                shape="pill"
                size="medium"
                locale={i18n.language}
              />
            </GoogleOAuthProvider>

            {/* <Button fullWidth size="large" color="inherit" variant="outlined">
          <FacebookIcon style={{ color: '#1877F2' }} />
        </Button>

        <Button fullWidth size="large" color="inherit" variant="outlined">
          <TwitterIcon style={{ color: '#1C9CEA' }} />
        </Button> */}
          </Box>
        )}
      </Box>

      {authError && !authLoading && (
        <Alert sx={{ mt: 2 }} severity="error">
          {authError}
        </Alert>
      )}

      <Divider sx={{ my: 3 }}>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          {t('or')}
        </Typography>
      </Divider>
    </>
  )
})

export default AuthSocial
