import FitnessCenterIcon from '@mui/icons-material/FitnessCenter'
import ViewListIcon from '@mui/icons-material/ViewList'
import {
  Box,
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material'
import { alpha, styled, useTheme } from '@mui/material/styles'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { matchPath, NavLink as RouterLink, useLocation } from 'react-router-dom'
import { ROUTE_DICTIONARY, ROUTE_WORKOUT } from 'src/resources/routes'
// import Iconify from './Iconify'

const ListItemStyle = styled((props: any) => (
  <ListItemButton disableGutters {...props} />
))(({ theme }) => ({
  ...theme.typography.body2,
  height: 48,
  position: 'relative',
  textTransform: 'capitalize',
  color: theme.palette.text.secondary,
  borderRadius: theme.shape.borderRadius,
}))

const ListItemIconStyle = styled(ListItemIcon)({
  width: 22,
  height: 22,
  color: 'inherit',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

function NavItem({ title, path, icon, info, active, children }: any) {
  const theme = useTheme()
  const { pathname } = useLocation()

  const match = (path: string): boolean =>
    path ? !!matchPath({ path, end: false }, pathname) : false

  const isActiveRoot = match(path)

  const [open, setOpen] = useState(isActiveRoot)

  const handleOpen = () => {
    setOpen((prev) => !prev)
  }

  const activeRootStyle = {
    color: 'primary.main',
    fontWeight: 'fontWeightMedium',
    bgcolor: alpha(
      theme.palette.primary.main,
      theme.palette.action.selectedOpacity,
    ),
  }

  const activeSubStyle = {
    color: 'text.primary',
    fontWeight: 'fontWeightMedium',
  }

  if (children) {
    return (
      <>
        <ListItemStyle
          onClick={handleOpen}
          sx={{
            ...(isActiveRoot && activeRootStyle),
          }}
        >
          <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
          <ListItemText disableTypography primary={title} />
          {info && info}
          {/* <Iconify
            icon={
              open
                ? 'eva:arrow-ios-downward-fill'
                : 'eva:arrow-ios-forward-fill'
            }
            sx={{ width: 16, height: 16, ml: 1 }}
          /> */}
        </ListItemStyle>

        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {children.map((item: any) => {
              const { title, path } = item
              const isActiveSub = active(path)

              return (
                <ListItemStyle
                  key={title}
                  component={RouterLink}
                  to={path}
                  sx={{
                    ...(isActiveSub && activeSubStyle),
                  }}
                >
                  <ListItemIconStyle>
                    <Box
                      component="span"
                      sx={{
                        width: 4,
                        height: 4,
                        display: 'flex',
                        borderRadius: '50%',
                        alignItems: 'center',
                        justifyContent: 'center',
                        bgcolor: 'text.disabled',
                        transition: (theme) =>
                          theme.transitions.create('transform'),
                        ...(isActiveSub && {
                          transform: 'scale(2)',
                          bgcolor: 'primary.main',
                        }),
                      }}
                    />
                  </ListItemIconStyle>
                  <ListItemText disableTypography primary={title} />
                </ListItemStyle>
              )
            })}
          </List>
        </Collapse>
      </>
    )
  }

  return (
    <ListItemStyle
      component={RouterLink}
      to={path}
      sx={{
        ...(isActiveRoot && activeRootStyle),
      }}
    >
      <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
      <ListItemText disableTypography primary={title} />
      {info && info}
    </ListItemStyle>
  )
}

const NavSection = () => {
  const { t } = useTranslation('common')

  return (
    <Box>
      <List disablePadding sx={{ p: 1 }}>
        <NavItem
          title={t('dictionary')}
          path={ROUTE_DICTIONARY}
          icon={<ViewListIcon />}
        />
        <NavItem
          title={t('workout')}
          path={ROUTE_WORKOUT}
          icon={<FitnessCenterIcon />}
        />
      </List>
    </Box>
  )
}

export default NavSection
