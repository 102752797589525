import { Box, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface IProps {
  title: string
}

const PublicDictionaryTitle = ({ title }: IProps) => {
  const { t } = useTranslation(['common'])
  return (
    <Box mb={4} textAlign="center">
      <Typography
        sx={{
          color: 'primary.main',
          fontSize: '40px',
        }}
      >
        {title}
      </Typography>
      <Typography color="grey.400" mt="-10px">
        {t('dictionary')}
      </Typography>
    </Box>
  )
}

export default PublicDictionaryTitle
