import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { BrowserRouter } from 'react-router-dom'
import UserStore from 'src/mobx/user'

import { getWhoAmI } from './api/requests/users'
import LoadingContainer from './components/common/LoadingContainer'
import { ACCESS_TOKEN_STORE_KEY } from './resources/localStorageKeys'
import Routes from './Routes'
import ThemeProvider from './theme'

const App = observer(() => {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    // Authorization
    const tokenLocal = localStorage.getItem(ACCESS_TOKEN_STORE_KEY)
    const tokenSession = sessionStorage.getItem(ACCESS_TOKEN_STORE_KEY)
    if (tokenLocal || tokenSession) {
      getWhoAmI()
        .then(async (data) => {
          UserStore.setUser(data.user)
        })
        .finally(() => setLoading(false))
    } else setLoading(false)
  }, [])

  return (
    <>
      {loading ? (
        <LoadingContainer />
      ) : (
        <BrowserRouter>
          <ThemeProvider>
            <Routes />
          </ThemeProvider>
        </BrowserRouter>
      )}
    </>
  )
})

export default App
