import { IDictionary } from 'src/types/Dictionary'
import { IWord } from 'src/types/Word'

import { client } from '../axios'
import { ApiRoutes } from '../routes'
import { ISuccess } from '../types/success'

// POST new Dictionary
export interface IDictionaryResponse {
  dictionary: IDictionary
}
export interface IPostDictionaryBody {
  title: string
  fromLanguageId: number
  toLanguageId: number
  isPublic?: boolean
}

export const postDictionary = async (
  body: IPostDictionaryBody,
): Promise<IDictionaryResponse> => {
  return (await client.post(ApiRoutes.postDictionary, body)).data
}

// PUT
export const updateDictionary = async (
  id: number,
  body: IPostDictionaryBody,
): Promise<IDictionaryResponse> => {
  return (await client.put(ApiRoutes.putDictionary(id), body)).data
}

// PATCH public status
export const updateDictionaryPublicStatus = async (
  id: number,
  isPublic: boolean,
): Promise<IDictionaryResponse> => {
  return (
    await client.patch(ApiRoutes.patchDictionaryPublicStatus(id), { isPublic })
  ).data
}

export interface IDictionarySettings {
  showTranscription?: boolean
  isPublic?: boolean
}
// PATCH dictionary settings
export const updateDictionarySettings = async (
  id: number,
  dictionarySettings: IDictionarySettings,
): Promise<IDictionaryResponse> => {
  return (
    await client.patch(
      ApiRoutes.patchDictionarySettings(id),
      dictionarySettings,
    )
  ).data
}

// GET Dictionary list
export interface IDictionariesResponse {
  dictionaries: IDictionary[]
}

export const getDictionaries = async (): Promise<IDictionariesResponse> => {
  return (await client.get(ApiRoutes.getDictionaries)).data
}

// GET public dictionary with words
export interface IPublicDictionaryResponse {
  publicDictionary: IDictionary
  publicDictionaryWords: IWord[]
  totalWordCount: number
}

export interface IPublicDictionaryParams {
  page?: number
}

export const getPublicDictionary = async (
  id: number,
  params?: IPublicDictionaryParams,
): Promise<IPublicDictionaryResponse> => {
  return (await client.get(ApiRoutes.getPublicDictionary(id), { params })).data
}

// DELETE Dictionary
export const deleteDictionary = async (id: number): Promise<ISuccess> => {
  return (await client.delete(ApiRoutes.deleteDictionary(id))).data
}
