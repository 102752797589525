import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getPublicWordUsageExamples } from 'src/api/requests/wordUsageExamples'
import LoadingContainer from 'src/components/common/LoadingContainer'
import { IWord } from 'src/types/Word'

import { WordUsageExampleItem } from './WordUsageExampleItem'

interface IProps {
  openedWord: IWord
  setOpenedWord: (w: IWord) => void
}

const WordUsageExample = ({ openedWord, setOpenedWord }: IProps) => {
  const { t } = useTranslation(['dictionaryPage', 'common'])
  const [examplesLoading, setExamplesLoading] = useState(false)
  const [expanded, setExpanded] = useState<boolean>(true)

  const toggleExpanded = () => setExpanded((prev) => !prev)

  useEffect(() => {
    if (!openedWord.examples) {
      setExamplesLoading(true)
      getPublicWordUsageExamples(openedWord.id)
        .then((res) => {
          const sortedExamples = res.wordUsageExamples.sort(
            (a, b) =>
              new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
          )

          const updatedWord = {
            ...openedWord,
            examples: sortedExamples,
          }

          setOpenedWord(updatedWord)
        })
        .finally(() => {
          setExamplesLoading(false)
        })
    }
  }, [openedWord])

  return (
    <Accordion
      sx={{ border: '2px solid #ededed' }}
      expanded={expanded}
      onChange={toggleExpanded}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={{ backgroundColor: '#f5f5f5' }}
      >
        <Typography color="gray">{t('word_usage_examples')}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {!examplesLoading ? (
          <Box>
            {openedWord.examples && (
              <Box>
                {openedWord.examples.length !== 0 ? (
                  openedWord.examples.map((example, id) => {
                    const isLastItem =
                      openedWord.examples &&
                      openedWord.examples.length == id + 1
                        ? true
                        : false
                    return (
                      <WordUsageExampleItem
                        key={example.id}
                        example={example}
                        hideUnderline={isLastItem}
                      />
                    )
                  })
                ) : (
                  <Typography>{t('no_results', { ns: 'common' })}</Typography>
                )}
              </Box>
            )}
          </Box>
        ) : (
          <LoadingContainer />
        )}
      </AccordionDetails>
    </Accordion>
  )
}

export default WordUsageExample
