import { Box, Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getDictionaryWords } from 'src/api/requests/words'
import { WordSortBy } from 'src/api/types/WordSortBy'
import { WordSortDirection } from 'src/api/types/WordSortDirection'
import LoadingContainer from 'src/components/common/LoadingContainer'
import Page from 'src/components/Page'
import useEventListener from 'src/hooks/useEventListener'
import DictionaryStore from 'src/mobx/dictionary'
import RememberFinishScreen from 'src/sections/dashboard/workouts/iRemember/RememberFinishScreen'
import RememberPlayScreen from 'src/sections/dashboard/workouts/iRemember/RememberPlayScreen'
import RememberSetupScreen from 'src/sections/dashboard/workouts/iRemember/RememberSetupScreen'
import { GameStages } from 'src/types/GameStages'
import { IWord } from 'src/types/Word'

const IRemember = observer(() => {
  const { t } = useTranslation(['workoutPages'])
  const [trainedWords, setTrainedWords] = useState<IWord[] | null>(null)
  const [isTranslation, setIsTranslation] = useState(true)

  const [gameStage, setGameStage] = useState<GameStages>(GameStages.Setup)

  const backLinkRef = useRef<HTMLAnchorElement>(null)

  const onFinish = () => {
    setGameStage(GameStages.End)
  }

  const onStart = async () => {
    if (DictionaryStore.selectedDictionary) {
      setGameStage(GameStages.Loading)
      await getDictionaryWords(DictionaryStore.selectedDictionary.id, {
        sortBy: WordSortBy.trainingLevel,
        sortDirection: WordSortDirection.ASC,
        limit: 25,
      })
        .then((data) => setTrainedWords(data.words))
        .finally(() => setGameStage(GameStages.Play))
    }
  }

  const onKeyboardDown = (e: KeyboardEvent) => {
    e.stopPropagation()

    if (
      e.key === 'ArrowDown' &&
      backLinkRef.current &&
      gameStage === GameStages.End
    )
      backLinkRef.current.click()
  }

  const changeLanguage = () => setIsTranslation((prev) => !prev)

  useEventListener('keydown', onKeyboardDown)

  useEffect(() => {
    if (DictionaryStore.dictionaries === null) DictionaryStore.getDictionaries()
  }, [])

  return (
    <Page title={t('i_remember')}>
      <Box
        margin={{
          lg: '0 70px 0 0',
          xs: '0 10px',
        }}
      >
        <Typography variant="h3" textAlign="center" mb="30px">
          {t('i_remember')}
        </Typography>

        {gameStage === GameStages.Setup && (
          <RememberSetupScreen
            onStart={onStart}
            isTranslation={isTranslation}
            onSwap={changeLanguage}
          />
        )}

        {gameStage === GameStages.Loading && <LoadingContainer />}

        {gameStage === GameStages.Play && trainedWords && (
          <RememberPlayScreen
            isTranslation={isTranslation}
            trainedWords={trainedWords}
            onFinish={onFinish}
          />
        )}

        {gameStage === GameStages.End && (
          <RememberFinishScreen ref={backLinkRef} />
        )}
      </Box>
    </Page>
  )
})

export default IRemember
