import { TableCell, TableRow } from '@mui/material'
import { IWord } from 'src/types/Word'

interface IProps {
  item: IWord
  showTranscription: boolean
  handleOpenWordModal: (w: IWord) => void
}

const PublicDictionaryWordRow = ({
  item,
  showTranscription,
  handleOpenWordModal,
}: IProps) => {
  return (
    <TableRow
      onClick={() => {
        handleOpenWordModal(item)
      }}
    >
      <TableCell align="left" sx={{ cursor: 'pointer' }}>
        {item.word}
      </TableCell>
      <TableCell align="left" sx={{ cursor: 'pointer' }}>
        {item.translation}
      </TableCell>
      {showTranscription && (
        <TableCell align="right" sx={{ cursor: 'pointer' }}>
          {item.transcription}
        </TableCell>
      )}
    </TableRow>
  )
}

export default PublicDictionaryWordRow
