import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import HelpIcon from '@mui/icons-material/Help'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Tooltip,
  Typography,
} from '@mui/material'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  deleteManyWordForms,
  getWordForms,
  postWordForm,
  updateWordForm,
} from 'src/api/requests/wordForms'
import LoadingContainer from 'src/components/common/LoadingContainer'
import DictionaryStore from 'src/mobx/dictionary'
import UserStore from 'src/mobx/user'
import { IWord } from 'src/types/Word'
import { IWordForm } from 'src/types/WordForm'

import { AddNewForm, WordFormItem } from './WordFormItem'

interface IProps {
  openedWord: IWord
  setOpenedWord: (w: IWord) => void
  somethingIsEditing: boolean
  setSomethingIsEditing: (v: boolean) => void
}

const WordForms = observer(
  ({
    openedWord,
    setOpenedWord,
    somethingIsEditing,
    setSomethingIsEditing,
  }: IProps) => {
    const { t } = useTranslation(['dictionaryPage'])
    const [formsLoading, setFormsLoading] = useState(false)
    const [expanded, setExpanded] = useState<boolean>(false)

    const onAddNewForm = (
      wordForm: Pick<IWordForm, 'form' | 'description'>,
    ): Promise<void> => {
      return postWordForm({
        ...wordForm,
        wordId: openedWord.id,
      }).then((res) => {
        if (openedWord.forms) {
          const updatedWord = {
            ...openedWord,
            forms: [...openedWord.forms, res.wordForm],
          }

          setOpenedWord(updatedWord)
          DictionaryStore.updateWord(updatedWord)
        }
      })
    }

    const onFormEdit = (form: IWordForm): Promise<void> => {
      const { id, ...body } = form
      return updateWordForm(id, body).then((res) => {
        if (openedWord.forms) {
          const index = openedWord.forms.findIndex(
            (i) => i.id === res.wordForm.id,
          )

          if (index >= 0) {
            const newFormsList = [
              ...openedWord.forms.slice(0, index),
              res.wordForm,
              ...openedWord.forms.slice(index + 1),
            ].sort(
              (a, b) =>
                new Date(a.createdAt).getTime() -
                new Date(b.createdAt).getTime(),
            )

            const updatedWord = {
              ...openedWord,
              forms: newFormsList,
            }

            setOpenedWord(updatedWord)
            DictionaryStore.updateWord(updatedWord)
          }
        }
      })
    }

    const onFormDelete = (formId: number): void => {
      deleteManyWordForms(openedWord.id, [formId]).then(() => {
        if (openedWord.forms) {
          const index = openedWord.forms.findIndex((i) => i.id === formId)

          if (index >= 0) {
            const newFormsList = [
              ...openedWord.forms.slice(0, index),
              ...openedWord.forms.slice(index + 1),
            ]

            const updatedWord = {
              ...openedWord,
              forms: newFormsList,
            }

            setOpenedWord(updatedWord)
            DictionaryStore.updateWord(updatedWord)
          }
        }
      })
    }

    useEffect(() => {
      if (!openedWord.forms && expanded) {
        setFormsLoading(true)
        getWordForms(openedWord.id)
          .then((res) => {
            const updatedWord = {
              ...openedWord,
              forms: res.wordForms,
            }

            setOpenedWord(updatedWord)
            DictionaryStore.updateWord(updatedWord)
          })
          .finally(() => {
            setFormsLoading(false)
          })
      }
    }, [openedWord, expanded])

    const toggleExpanded = () => setExpanded((prev) => !prev)

    const formsLimit = UserStore.user && UserStore.user?.isPremium ? 20 : 4

    return (
      <Accordion sx={{ border: '1px solid #ededed' }} onChange={toggleExpanded}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{ backgroundColor: '#f5f5f5' }}
        >
          <Box display="flex" gap="10px">
            <Typography color="gray">{t('word_forms')}</Typography>
            <Box position="relative">
              <Box position="absolute">
                <Tooltip title={t('what_is_word_form')} placement="top">
                  <Typography>
                    <HelpIcon color="disabled" />
                  </Typography>
                </Tooltip>
              </Box>
            </Box>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          {!formsLoading ? (
            <Box>
              {openedWord.forms && (
                <Box>
                  {openedWord.forms.length !== 0 &&
                    openedWord.forms.map((wordForm) => (
                      <WordFormItem
                        key={wordForm.id}
                        wordForm={wordForm}
                        onFormEdit={onFormEdit}
                        onFormDelete={onFormDelete}
                        somethingIsEditing={somethingIsEditing}
                        setSomethingIsEditing={setSomethingIsEditing}
                      />
                    ))}
                </Box>
              )}
              {(!openedWord.forms || openedWord.forms.length < formsLimit) && (
                <AddNewForm
                  onAddNewForm={onAddNewForm}
                  somethingIsEditing={somethingIsEditing}
                  setSomethingIsEditing={setSomethingIsEditing}
                />
              )}
            </Box>
          ) : (
            <LoadingContainer />
          )}
        </AccordionDetails>
      </Accordion>
    )
  },
)

export default WordForms
