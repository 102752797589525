import { Box, Card, Container, Link, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink } from 'react-router-dom'
import AuthSocial from 'src/components/forms/AuthSocial'
import LoginForm from 'src/components/forms/LoginForm'
import Logo from 'src/components/Logo'
import Page from 'src/components/Page'
import useResponsive from 'src/hooks/useResponsive'
import { PATH_REGISTER } from 'src/resources/routes'

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}))

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7),
  },
}))

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
}))

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}))

const Login = () => {
  const { t } = useTranslation('loginPage')

  const smUp = useResponsive('up', 'sm')
  const mdUp = useResponsive('up', 'md')

  return (
    <Page title="Login">
      <Box>
        <RootStyle>
          <HeaderStyle>
            <Logo />

            {smUp && (
              <Typography variant="body2" sx={{ mt: { md: -2 } }}>
                {t('dont_have_account')} {''}
                <Link
                  variant="subtitle2"
                  component={RouterLink}
                  to={`/${PATH_REGISTER}`}
                >
                  {t('register')}
                </Link>
              </Typography>
            )}
          </HeaderStyle>

          {mdUp && (
            <SectionStyle>
              <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
                {t('title')}
              </Typography>
              <img
                src="/static/illustrations/illustration_login.png"
                alt="login"
              />
            </SectionStyle>
          )}

          <Container maxWidth="sm">
            <ContentStyle>
              <Typography variant="h4" gutterBottom>
                {t('your_account')}
              </Typography>

              <Typography sx={{ color: 'text.secondary', mb: 5 }}>
                {t('enter_data')}
              </Typography>

              <AuthSocial />

              <LoginForm />

              {!smUp && (
                <Typography variant="body2" align="center" sx={{ mt: 3 }}>
                  {t('dont_have_account')}{' '}
                  <Link
                    variant="subtitle2"
                    component={RouterLink}
                    to={`/${PATH_REGISTER}`}
                  >
                    {t('register')}
                  </Link>
                </Typography>
              )}
            </ContentStyle>
          </Container>
        </RootStyle>
      </Box>
    </Page>
  )
}

export default Login
