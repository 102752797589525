import {
  Avatar,
  Box,
  Divider,
  IconButton,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material'
import { alpha } from '@mui/material/styles'
import { observer } from 'mobx-react-lite'
import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import account from 'src/_mock/account'
import { getSignout } from 'src/api/requests/auth'
import { postUserReport } from 'src/api/requests/words'
import TextareaForm from 'src/components/forms/TextareaForm'
import MenuPopover from 'src/components/MenuPopover'
import ModalWrapper from 'src/components/modals/ModalWrapper'
import DictionaryStore from 'src/mobx/dictionary'
import UserStore from 'src/mobx/user'
import { ACCESS_TOKEN_STORE_KEY } from 'src/resources/localStorageKeys'
import { ROUTE_DICTIONARY, ROUTE_LOGIN } from 'src/resources/routes'

const MENU_OPTIONS = [
  {
    label: 'Dashboard',
    linkTo: ROUTE_DICTIONARY,
  },
  // {
  //   label: 'Profile',
  //   linkTo: ROUTE_PROFILE,
  // },
  // {
  //   label: 'Settings',
  //   linkTo: ROUTE_SETTINGS,
  // },
]

const AccountPopover = observer(() => {
  const anchorRef = useRef(null)
  const { t } = useTranslation(['common'])

  const [open, setOpen] = useState<boolean | null>(null)
  const [isReportModal, setIsReportModal] = useState(false)

  const handleReportModalClose = () => {
    setIsReportModal(false)
  }
  const handleReportModalOpen = () => {
    setOpen(false)
    setIsReportModal(true)
  }
  const handleReportSubmit = async (message: string) =>
    await postUserReport(message).then(() => setIsReportModal(false))

  const handleOpen = (event: any) => {
    setOpen(event.currentTarget)
  }

  const navigate = useNavigate()

  const handleClose = () => {
    setOpen(null)
  }

  const handleLogout = async () => {
    await getSignout().then(() => {
      localStorage.removeItem(ACCESS_TOKEN_STORE_KEY)
      sessionStorage.removeItem(ACCESS_TOKEN_STORE_KEY)
      navigate(ROUTE_LOGIN, { replace: true })
      setOpen(null)
      DictionaryStore.setDictionaries(null)
      UserStore.setUser(null)
    })
  }

  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <Avatar src={account.photoURL} alt="photoURL" />
      </IconButton>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {UserStore.user?.name}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {UserStore.user?.email}
          </Typography>
        </Box>

        <Divider />

        {/* MENU ITEMS */}
        <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem
              key={option.label}
              to={option.linkTo}
              component={RouterLink}
              onClick={handleClose}
            >
              {option.label}
            </MenuItem>
          ))}
        </Stack>

        <Divider />

        <Stack sx={{ p: 1 }}>
          <MenuItem onClick={handleReportModalOpen}>{t('report')}</MenuItem>
          <MenuItem onClick={handleLogout}>{t('logout')}</MenuItem>
        </Stack>
      </MenuPopover>

      <ModalWrapper
        isOpen={isReportModal}
        onClose={handleReportModalClose}
        title={t('input_your_message')}
      >
        <TextareaForm onSubmit={handleReportSubmit} />
      </ModalWrapper>
    </>
  )
})

export default AccountPopover
