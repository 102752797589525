import { client } from '../axios'
import { ApiRoutes } from '../routes'
import { IUserResponse } from '../types/user'

interface IPostLoginBody {
  email: string
  password: string
}

interface ILoginResponse {
  user: IUserResponse
  accessToken: string
}

export const postLogin = async (
  body: IPostLoginBody,
): Promise<ILoginResponse> => {
  return (
    await client.post(ApiRoutes.postLogin, body, { withCredentials: true })
  ).data
}

export const authGoogle = async (token: string): Promise<ILoginResponse> => {
  return (await client.post(ApiRoutes.postGoogleAuth, { token })).data
}

export const getActivate = async (id: string): Promise<ILoginResponse> => {
  return (await client.get(`${ApiRoutes.getActivate}/${id}`)).data
}

export const getSignout = async (): Promise<void> => {
  return await client.get(ApiRoutes.getSignout, { withCredentials: true })
}
