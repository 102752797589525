import EvStationIcon from '@mui/icons-material/EvStation'
import GavelIcon from '@mui/icons-material/Gavel'
import { Box } from '@mui/material'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import CardWithIcon from 'src/components/common/CardWithIcon'
import Page from 'src/components/Page'
import {
  PATH_DASHBOARD,
  PATH_I_REMEMBER,
  PATH_LETTER_HAMMER,
  PATH_WORKOUT,
} from 'src/resources/routes'

const Workouts = () => {
  const { t } = useTranslation(['workoutPages'])

  const navigate = useNavigate()

  const redirectToWorkout = (trainingPath: string) => {
    navigate(`/${PATH_DASHBOARD}/${PATH_WORKOUT}/${trainingPath}`)
  }

  return (
    <Page title={t('workouts')}>
      <Typography mb="20px" textAlign="center" variant="h5" component="h2">
        {t('workouts')}
      </Typography>

      <Box mx={{ lg: '0', xs: '15px' }} display="flex" gap={2}>
        <CardWithIcon
          onClick={() => redirectToWorkout(PATH_I_REMEMBER)}
          title="iRemember"
          icon={EvStationIcon}
        />
        <CardWithIcon
          cardColor="success.main"
          iconColor="success.lighter"
          onClick={() => redirectToWorkout(PATH_LETTER_HAMMER)}
          title="Letter Hammer"
          icon={GavelIcon}
        />
      </Box>
    </Page>
  )
}

export default Workouts
