import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { LoadingButton } from '@mui/lab'
import {
  Alert,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
} from '@mui/material'
import { Form, FormikProvider, useFormik } from 'formik'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { postUser } from 'src/api/requests/users'
import { getError } from 'src/utils/getError'
import * as Yup from 'yup'

interface IProps {
  setCongratulation: (e: boolean) => void
}

export default function RegisterForm({ setCongratulation }: IProps) {
  const { t } = useTranslation(['registerPage', 'error'])

  const [showPassword, setShowPassword] = useState(false)
  const [error, setError] = useState<string | null>(null)

  const RegisterSchema = Yup.object().shape({
    email: Yup.string()
      .email(t('invalid_email', { ns: 'error' }))
      .required(t('email_required', { ns: 'error' })),
    password: Yup.string()
      .required(t('password_required', { ns: 'error' }))
      .min(8, t('min_8', { ns: 'error' })),
    name: Yup.string()
      .min(2, t('too_short', { ns: 'error' }))
      .max(30, t('too_long', { ns: 'error' }))
      .required(t('first_name_required', { ns: 'error' })),
  })

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      name: '',
    },
    validationSchema: RegisterSchema,
    onSubmit: () => {
      onSubmit()
    },
  })

  const {
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    setSubmitting,
    getFieldProps,
    values,
  } = formik

  const onSubmit = () => {
    postUser(values)
      .then(() => {
        setCongratulation(true)
        setError(null)
      })
      .catch((err) => {
        setError(getError(err).message)
      })
      .finally(() => setSubmitting(false))
  }

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label={t('name')}
              {...getFieldProps('name')}
              error={Boolean(touched.name && errors.name)}
              helperText={touched.name && errors.name}
            />
          </Stack>

          <TextField
            fullWidth
            autoComplete="username"
            type="email"
            label={t('email')}
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label={t('password')}
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    onClick={() => setShowPassword((prev) => !prev)}
                  >
                    {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />

          {error && !isSubmitting && <Alert severity="error">{error}</Alert>}

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            {t('register')}
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  )
}
