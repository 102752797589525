import { LoadingButton } from '@mui/lab'
import { Alert, Stack, TextField } from '@mui/material'
import { Form, FormikProvider, useFormik } from 'formik'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'

interface IProps {
  onSubmit: (
    values: { email: string },
    setError: (message: string) => void,
  ) => Promise<void>
}

const EmailForm = ({ onSubmit }: IProps) => {
  const [error, setError] = useState<string | null>(null)

  const { t } = useTranslation(['common', 'error'])

  const EmailSchema = Yup.object().shape({
    email: Yup.string()
      .email(t('invalid_email', { ns: 'error' }))
      .required(t('email_required', { ns: 'error' })),
  })

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: EmailSchema,
    onSubmit: () => {
      onSubmit(values, setError).finally(() => setSubmitting(false))
    },
  })

  const {
    errors,
    touched,
    values,
    isSubmitting,
    setSubmitting,
    handleSubmit,
    getFieldProps,
  } = formik

  return (
    <FormikProvider value={formik}>
      <Form
        style={{ minWidth: '350px' }}
        autoComplete="off"
        noValidate
        onSubmit={handleSubmit}
      >
        <Stack spacing={3}>
          <TextField
            fullWidth
            autoComplete="username"
            type="email"
            label={t('email')}
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />
        </Stack>

        {error && !isSubmitting && (
          <Alert sx={{ mt: 2 }} severity="error">
            {error}
          </Alert>
        )}

        <LoadingButton
          sx={{ mt: 2 }}
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
        >
          {t('confirm')}
        </LoadingButton>
      </Form>
    </FormikProvider>
  )
}

export default EmailForm
