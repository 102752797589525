import styled from '@emotion/styled'
import { Box, IconButton } from '@mui/material'

const StyledBox = styled(Box)(() => ({
  display: 'flex',
  gap: '5px',
  alignItems: 'center',
  transition: '.2s',
  cursor: 'pointer',
  flexGrow: 1,
}))

const StyledIconBtn = styled(IconButton)(() => ({
  transition: '.2s',
  '&:hover': {
    color: '#525252',
  },
}))

interface IProps {
  onClick: () => void
  icon: any
  dataTestAttr?: string
}

const IconButtonWrapper = ({ onClick, icon: Icon, dataTestAttr }: IProps) => (
  <StyledBox data-test={dataTestAttr} onClick={onClick}>
    <StyledIconBtn>
      <Icon />
    </StyledIconBtn>
  </StyledBox>
)

export default IconButtonWrapper
