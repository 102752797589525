import { IWord } from 'src/types/Word'

import { client } from '../axios'
import { ApiRoutes } from '../routes'
import { WordSortBy } from '../types/WordSortBy'
import { WordSortDirection } from '../types/WordSortDirection'
import { ISuccess } from './../types/success'

// Get Dictionary Words list
export interface IDictionaryWordsResponse {
  words: IWord[]
  otherDictionaryWords: IWord[]
  totalCount: number
}

export interface IDictionaryWordsParams {
  filter?: string
  limit?: number
  page?: number
  sortBy?: WordSortBy
  sortDirection?: WordSortDirection
}

export const getDictionaryWords = async (
  dictionaryId: number,
  params?: IDictionaryWordsParams,
): Promise<IDictionaryWordsResponse> => {
  return (
    await client.get(ApiRoutes.getDictionaryWords(dictionaryId), {
      params,
    })
  ).data
}

// Post Word
export interface IPostWordsBody {
  word: string
  translation: string
  transcription?: string
  dictionaryId: number
}

export interface IPostWordResponse {
  word: IWord
}

export const postWord = async (
  body: IPostWordsBody,
): Promise<IPostWordResponse> => {
  return (await client.post(ApiRoutes.postWord, body)).data
}

// Delete many word
export const deleteManyWords = async (wordIds: number[]): Promise<ISuccess> => {
  return (
    await client.delete(ApiRoutes.deleteManyWords, {
      data: {
        wordIds: wordIds,
      },
    })
  ).data
}

// Update word
interface IPutUpdateBoyd {
  translation: string
  transcription?: string
  dictionaryId: number
}

interface IPutWordResponse {
  word: IWord
}

export const updateWord = async (
  id: number,
  body: IPutUpdateBoyd,
): Promise<IPutWordResponse> => {
  return (await client.put(ApiRoutes.updateWord(id), body)).data
}

interface IPatchWordTrainingLevelBody {
  id: number
  dictionaryId: number
  trainingLevel: number
}

export const updateWordsTrainingLevel = async (
  body: IPatchWordTrainingLevelBody[],
): Promise<ISuccess> => {
  return (
    await client.patch(ApiRoutes.updateWordsTrainingLevel, {
      wordsToUpdate: body,
    })
  ).data
}

interface IPatchWordsDictionaryBody {
  wordIds: number[]
  dictionaryId: number
}

export const updateWordsDictionary = async (
  body: IPatchWordsDictionaryBody,
): Promise<ISuccess> => {
  return (await client.patch(ApiRoutes.updateWordsDictionary, body)).data
}

// POST User's Report
export const postUserReport = async (message: string): Promise<ISuccess> => {
  return (await client.post(ApiRoutes.postUserReport, { message })).data
}
