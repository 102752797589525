import axios, { AxiosError } from 'axios'
import { IApiError } from 'src/types/ApiError'

export const getError = (error: AxiosError<IApiError, any>): IApiError => {
  if (axios.isAxiosError(error)) {
    const online = window.navigator.onLine
    if (!online)
      return {
        message: 'You are offline',
        code: 'NOT_ONLINE',
      }

    if (error.request.data) return error.request.data.message

    if (error.response && error.response.data)
      return {
        message: error.response.data.message,
        code: error.response.data.code,
      }
  }

  return {
    message: error.message || 'Something went wrong, try again late.',
    code: 'SMTH_WRONG',
  }
}
