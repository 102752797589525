import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from '@mui/material'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  deleteManyWordUsageExamples,
  getWordUsageExamples,
  postWordUsageExample,
  updateWordUsageExample,
} from 'src/api/requests/wordUsageExamples'
import LoadingContainer from 'src/components/common/LoadingContainer'
import DictionaryStore from 'src/mobx/dictionary'
import UserStore from 'src/mobx/user'
import { IWord } from 'src/types/Word'
import { IWordUsageExample } from 'src/types/WordUsageExample'

import { AddNewExample, WordUsageExampleItem } from './WordUsageExampleItem'

interface IProps {
  openedWord: IWord
  setOpenedWord: (w: IWord) => void
  somethingIsEditing: boolean
  setSomethingIsEditing: (v: boolean) => void
}

const WordUsageExample = observer(
  ({
    openedWord,
    setOpenedWord,
    somethingIsEditing,
    setSomethingIsEditing,
  }: IProps) => {
    const { t } = useTranslation(['dictionaryPage'])
    const [examplesLoading, setExamplesLoading] = useState(false)
    const [expanded, setExpanded] = useState<boolean>(true)

    const toggleExpanded = () => setExpanded((prev) => !prev)

    const onAddNewExample = (
      example: Pick<IWordUsageExample, 'sentence' | 'translation'>,
    ): Promise<void> => {
      return postWordUsageExample({
        ...example,
        wordId: openedWord.id,
      }).then((res) => {
        if (openedWord.examples) {
          const updatedWord = {
            ...openedWord,
            examples: [...openedWord.examples, res.wordUsageExample],
          }

          setOpenedWord(updatedWord)
          DictionaryStore.updateWord(updatedWord)
        }
      })
    }

    const onExampleEdit = (example: IWordUsageExample): Promise<void> => {
      const { id, ...body } = example
      return updateWordUsageExample(id, body).then((res) => {
        if (openedWord.examples) {
          const index = openedWord.examples.findIndex(
            (i) => i.id === res.wordUsageExample.id,
          )

          if (index >= 0) {
            const newExamplesList = [
              ...openedWord.examples.slice(0, index),
              res.wordUsageExample,
              ...openedWord.examples.slice(index + 1),
            ].sort(
              (a, b) =>
                new Date(a.createdAt).getTime() -
                new Date(b.createdAt).getTime(),
            )

            const updatedWord = {
              ...openedWord,
              examples: newExamplesList,
            }

            setOpenedWord(updatedWord)
            DictionaryStore.updateWord(updatedWord)
          }
        }
      })
    }

    const onExampleDelete = (exampleId: number): void => {
      deleteManyWordUsageExamples(openedWord.id, [exampleId]).then(() => {
        if (openedWord.examples) {
          const index = openedWord.examples.findIndex((i) => i.id === exampleId)

          if (index >= 0) {
            const newExamplesList = [
              ...openedWord.examples.slice(0, index),
              ...openedWord.examples.slice(index + 1),
            ]

            const updatedWord = {
              ...openedWord,
              examples: newExamplesList,
            }

            setOpenedWord(updatedWord)
            DictionaryStore.updateWord(updatedWord)
          }
        }
      })
    }

    useEffect(() => {
      if (!openedWord.examples) {
        setExamplesLoading(true)
        getWordUsageExamples(openedWord.id)
          .then((res) => {
            const updatedWord = {
              ...openedWord,
              examples: res.wordUsageExamples,
            }

            setOpenedWord(updatedWord)
            DictionaryStore.updateWord(updatedWord)
          })
          .finally(() => {
            setExamplesLoading(false)
          })
      }
    }, [openedWord])

    const examplesLimit = UserStore.user && UserStore.user?.isPremium ? 20 : 4

    return (
      <Accordion
        sx={{ border: '2px solid #ededed' }}
        expanded={expanded}
        onChange={toggleExpanded}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{ backgroundColor: '#f5f5f5' }}
        >
          <Typography color="gray">{t('word_usage_examples')}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {!examplesLoading ? (
            <Box>
              {openedWord.examples && (
                <Box>
                  {openedWord.examples.length !== 0 &&
                    openedWord.examples.map((example) => (
                      <WordUsageExampleItem
                        key={example.id}
                        example={example}
                        onExampleEdit={onExampleEdit}
                        onExampleDelete={onExampleDelete}
                        somethingIsEditing={somethingIsEditing}
                        setSomethingIsEditing={setSomethingIsEditing}
                      />
                    ))}
                </Box>
              )}
              {(!openedWord.examples ||
                openedWord.examples.length < examplesLimit) && (
                <AddNewExample
                  onAddNewExample={onAddNewExample}
                  somethingIsEditing={somethingIsEditing}
                  setSomethingIsEditing={setSomethingIsEditing}
                />
              )}
            </Box>
          ) : (
            <LoadingContainer />
          )}
        </AccordionDetails>
      </Accordion>
    )
  },
)

export default WordUsageExample
