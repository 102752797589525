import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import { Alert, Box, Button, Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { updateWordsTrainingLevel } from 'src/api/requests/words'
import useEventListener from 'src/hooks/useEventListener'
import DictionaryStore from 'src/mobx/dictionary'
import { IWord } from 'src/types/Word'

interface IProps {
  isTranslation: boolean
  trainedWords: IWord[]
  onFinish: () => void
}

const RememberPlayScreen = observer(
  ({ isTranslation, trainedWords, onFinish }: IProps) => {
    const { t } = useTranslation(['workoutPages'])
    const [showTranslation, setShowTranslation] = useState(false)
    const [currentWord, setCurrentWord] = useState<number>(0)

    const onAnswer = (updatedWord: IWord) => {
      const transformedWord = {
        id: updatedWord.id,
        dictionaryId: updatedWord.dictionaryId,
        trainingLevel: updatedWord.trainingLevel,
      }

      DictionaryStore.updateWord(updatedWord)
      updateWordsTrainingLevel([transformedWord])

      setShowTranslation(true)
    }

    const onRemember = () => {
      if (trainedWords) {
        const curWord = trainedWords[currentWord]
        curWord.trainingLevel = curWord.trainingLevel + 1

        onAnswer(curWord)
      }
    }

    const onNext = async () => {
      if (trainedWords) {
        if (currentWord + 1 < trainedWords.length) {
          setShowTranslation(false)

          setCurrentWord((prev) => prev + 1)
        } else onFinish()
      }
    }

    const onDontRemember = () => {
      if (trainedWords) {
        const curWord = trainedWords[currentWord]
        curWord.trainingLevel =
          curWord.trainingLevel === 0 ? 0 : curWord.trainingLevel - 1

        onAnswer(curWord)
      }
    }

    const onKeyboardDown = (e: KeyboardEvent) => {
      e.stopPropagation()

      if (trainedWords) {
        if (!showTranslation) {
          if (e.key === 'ArrowRight') onRemember()

          if (e.key === 'ArrowLeft') onDontRemember()
        } else if (e.key === 'ArrowUp') {
          onNext()
        }
      }
    }

    useEventListener('keydown', onKeyboardDown)

    return (
      <Box>
        {trainedWords.length > 0 ? (
          <Box textAlign="center">
            <Typography color="lightgray">{t('answer_question')}</Typography>
            <Typography
              sx={{
                fontSize: {
                  md: '24px',
                  xs: '13px',
                },
              }}
            >
              {t('do_you_remember')}
            </Typography>
            <Typography
              sx={{
                fontSize: {
                  md: '50px',
                  xs: '22px',
                },
                color: '#e02fd5',
                textTransform: 'uppercase',
                fontWeight: 'bold',
                margin: '30px 0 -5px',
              }}
            >
              {!isTranslation
                ? trainedWords[currentWord].translation
                : trainedWords[currentWord].word}
            </Typography>

            {showTranslation ? (
              <Box>
                {trainedWords[currentWord].transcription && (
                  <Typography color="gray">
                    {trainedWords[currentWord].transcription}
                  </Typography>
                )}
                <Typography
                  sx={{
                    marginTop: '20px',
                    fontSize: { md: '30px', xs: '20px' },
                    fontWeight: 'bold',
                  }}
                >
                  {isTranslation
                    ? trainedWords[currentWord].translation
                    : trainedWords[currentWord].word}
                </Typography>
                <Button
                  variant="contained"
                  onClick={onNext}
                  style={{
                    width: '100%',
                    maxWidth: '400px',
                    marginTop: '40px',
                  }}
                >
                  {t('next')}
                  <Box
                    component="span"
                    ml="10px"
                    display={{ md: 'flex', xs: 'none' }}
                    alignItems="center"
                    style={{ transform: 'rotate(90deg)' }}
                  >
                    <KeyboardBackspaceIcon />
                  </Box>
                </Button>
              </Box>
            ) : (
              <Box
                display="flex"
                justifyContent="space-between"
                margin="50px auto 0"
                gap="10px"
                width={{
                  sm: '500px',
                  xs: '100%',
                }}
              >
                <Button
                  variant="contained"
                  color="error"
                  fullWidth
                  onClick={onDontRemember}
                >
                  {t('dont_remember')}
                  <Box
                    component="span"
                    ml="10px"
                    display={{ md: 'flex', xs: 'none' }}
                    alignItems="center"
                  >
                    <KeyboardBackspaceIcon />
                  </Box>
                </Button>
                <Button
                  fullWidth
                  variant="contained"
                  color="success"
                  onClick={onRemember}
                >
                  {t('remember')}

                  <Box
                    component="span"
                    ml="10px"
                    display={{ md: 'flex', xs: 'none' }}
                    alignItems="center"
                    style={{ transform: 'rotate(180deg)' }}
                  >
                    <KeyboardBackspaceIcon />
                  </Box>
                </Button>
              </Box>
            )}
          </Box>
        ) : (
          <Alert color="error">{t('no_words_in_dictionary')}</Alert>
        )}
      </Box>
    )
  },
)

export default RememberPlayScreen
