import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { LoadingButton } from '@mui/lab'
import {
  Alert,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Link,
  Stack,
  TextField,
} from '@mui/material'
import { Form, FormikProvider, useFormik } from 'formik'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Link as RouterLink } from 'react-router-dom'
import { postLogin } from 'src/api/requests/auth'
import UserStore from 'src/mobx/user'
import { ACCESS_TOKEN_STORE_KEY } from 'src/resources/localStorageKeys'
import { PATH_RESTORE_PASSWORD, ROUTE_DICTIONARY } from 'src/resources/routes'
import { getError } from 'src/utils/getError'
import * as Yup from 'yup'

const LoginForm = observer(() => {
  const { t } = useTranslation(['loginPage', 'error'])
  const navigate = useNavigate()

  const [showPassword, setShowPassword] = useState(false)
  const [error, setError] = useState<string | null>(null)

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email(t('invalid_email', { ns: 'error' }))
      .required(t('email_required', { ns: 'error' })),
    password: Yup.string().required(t('password_required', { ns: 'error' })),
  })

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      remember: true,
    },
    validationSchema: LoginSchema,
    onSubmit: () => {
      onSubmit()
    },
  })

  const {
    errors,
    touched,
    values,
    isSubmitting,
    setSubmitting,
    handleSubmit,
    getFieldProps,
  } = formik

  const handleShowPassword = () => {
    setShowPassword((show) => !show)
  }

  const setStorage = (data: any) => {
    if (values.remember) {
      localStorage.setItem(ACCESS_TOKEN_STORE_KEY, data)
    } else {
      sessionStorage.setItem(ACCESS_TOKEN_STORE_KEY, data)
    }
  }

  const onSubmit = () => {
    postLogin(values)
      .then((data) => {
        setStorage(data.accessToken)
        UserStore.setUser(data.user)
        setError(null)
        navigate(ROUTE_DICTIONARY, { replace: true })
      })
      .catch((err) => {
        setError(getError(err).message)
      })
      .finally(() => {
        setSubmitting(false)
      })
  }

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            autoComplete="username"
            type="email"
            label={t('email')}
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label={t('password')}
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
        </Stack>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ my: 2 }}
        >
          <FormControlLabel
            control={
              <Checkbox
                {...getFieldProps('remember')}
                checked={values.remember}
              />
            }
            label={t('remember_me')}
          />

          <Link
            component={RouterLink}
            variant="subtitle2"
            to={`/${PATH_RESTORE_PASSWORD}`}
            underline="hover"
          >
            {t('forgot_password')}
          </Link>
        </Stack>

        {error && !isSubmitting && (
          <Alert sx={{ mb: 1 }} severity="error">
            {error}
          </Alert>
        )}

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
        >
          {t('login')}
        </LoadingButton>
      </Form>
    </FormikProvider>
  )
})

export default LoginForm
