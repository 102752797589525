import { IWordUsageExample } from 'src/types/WordUsageExample'

import { client } from '../axios'
import { ApiRoutes } from '../routes'
import { ISuccess } from './../types/success'

export interface IWordUsageExamplesResponse {
  wordUsageExamples: IWordUsageExample[]
}

export interface IWordUsageExampleResponse {
  wordUsageExample: IWordUsageExample
}

// GET (all)
export const getWordUsageExamples = async (
  wordId: number,
): Promise<IWordUsageExamplesResponse> => {
  return (await client.get(ApiRoutes.getWordUsageExamples(wordId))).data
}

// GET Public (all)
export const getPublicWordUsageExamples = async (
  wordId: number,
): Promise<IWordUsageExamplesResponse> => {
  return (await client.get(ApiRoutes.getPublicWordUsageExamples(wordId))).data
}

// POST (one)
export const postWordUsageExample = async (
  body: Omit<IWordUsageExample, 'id' | 'createdAt'>,
): Promise<IWordUsageExampleResponse> => {
  return (
    await client.post(ApiRoutes.postWordUsageExample, {
      wordUsageExample: body,
    })
  ).data
}

// PUT (one)
export const updateWordUsageExample = async (
  id: number,
  body: Omit<IWordUsageExample, 'id' | 'createdAt'>,
): Promise<IWordUsageExampleResponse> => {
  return (
    await client.put(ApiRoutes.updateWordUsageExample(id), {
      wordUsageExample: body,
    })
  ).data
}

// Delete (many)
export const deleteManyWordUsageExamples = async (
  wordId: number,
  wordUsageExampleIds: number[],
): Promise<ISuccess> => {
  return (
    await client.delete(ApiRoutes.deleteManyWordUsageExamples(wordId), {
      data: {
        wordUsageExampleIds,
      },
    })
  ).data
}
