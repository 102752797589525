import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { Box, Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import {
  IDictionarySettings,
  updateDictionarySettings,
} from 'src/api/requests/dictionaries'
import DictionaryStore from 'src/mobx/dictionary'
import { ROUTE_PUBLIC_DICTIONARY } from 'src/resources/routes'

import IconButtonWrapper from '../buttons/IconButtonWrapper'
import CheckboxForm from './CheckboxForm'

const DictionarySettingsForm = observer(() => {
  const { t } = useTranslation(['dictionaryPage'])

  const dictionaryId = DictionaryStore.selectedDictionary?.id as number
  const publicDictionaryLink = `${window.location.origin}${ROUTE_PUBLIC_DICTIONARY}/${dictionaryId}`

  const onCopy = () => {
    navigator.clipboard.writeText(publicDictionaryLink)
  }

  const onChange = (dictionarySettings: IDictionarySettings): Promise<void> => {
    if (DictionaryStore.selectedDictionary) {
      const dictionaryId = DictionaryStore.selectedDictionary.id
      return updateDictionarySettings(dictionaryId, dictionarySettings).then(
        (res) => DictionaryStore.updateSelectedDictionary(res.dictionary),
      )
    }
    return Promise.reject(new Error('Dictionary is not selected'))
  }

  const onIsPublicChange = (isPublic: boolean) => onChange({ isPublic })
  const onShowTranscriptionChange = (showTranscription: boolean) =>
    onChange({ showTranscription })

  const isPublicChecked = DictionaryStore.selectedDictionary
    ? DictionaryStore.selectedDictionary.isPublic
    : false

  const isTranscriptionChecked = DictionaryStore.selectedDictionary
    ? DictionaryStore.selectedDictionary.showTranscription
    : false

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
      <CheckboxForm
        initalValue={isPublicChecked}
        onChange={onIsPublicChange}
        label={t('make_dictionary_public')}
        component={
          <Box
            bgcolor="success.lighter"
            padding="10px"
            pr="33px"
            borderRadius={1}
            mt={1}
            position="relative"
          >
            <Typography style={{ overflowWrap: 'break-word' }}>
              {publicDictionaryLink}
            </Typography>
            <Box position="absolute" top="1px" right="1px">
              <IconButtonWrapper icon={ContentCopyIcon} onClick={onCopy} />
            </Box>
          </Box>
        }
      />

      <CheckboxForm
        initalValue={isTranscriptionChecked}
        onChange={onShowTranscriptionChange}
        label={t('show_transcription')}
      />
    </Box>
  )
})

export default DictionarySettingsForm
