import { makeAutoObservable } from 'mobx'

class UIStore {
  isHeaderMinimized = false
  constructor() {
    makeAutoObservable(this)
  }

  minimizeUIHeader(isHeaderMinimized: boolean) {
    this.isHeaderMinimized = isHeaderMinimized
  }
}

export default new UIStore()
