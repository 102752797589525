import { LoadingButton } from '@mui/lab'
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import SelectWithFilter, { ISelectOption } from '../inputs/SelectWithFilter'

interface IProps {
  open: boolean
  handleClose: () => void
  onConfirm: (value: any) => void
  selectOptions: ISelectOption[]
  title?: string
  loading?: boolean
  error?: string | null
  value?: any
}

const ListModal = ({
  open,
  handleClose,
  onConfirm,
  selectOptions,
  title,
  loading,
  error,
  value,
}: IProps) => {
  const { t } = useTranslation(['common'])
  const [selectedValue, setSelectedValue] = useState(
    value || selectOptions[0].value,
  )

  const handleEnter = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') onConfirm(selectedValue)
  }

  const onSelectChange = (value: any) => {
    setSelectedValue(value)
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      onKeyDown={handleEnter}
    >
      <Box sx={{ width: { sm: 400, xs: '100%' } }}>
        <DialogTitle id="responsive-dialog-title">
          {title || t('choose_dictionary')}
        </DialogTitle>
        <DialogContent>
          <SelectWithFilter
            onChange={(value) => onSelectChange(value)}
            options={selectOptions}
            value={selectedValue}
          />

          {error && !loading && <Alert severity="error">{error}</Alert>}
        </DialogContent>

        <DialogActions
          sx={{ display: 'flex', justifyContent: 'center', gap: '40px' }}
        >
          <LoadingButton
            variant="contained"
            loading={loading}
            onClick={() => onConfirm(selectedValue)}
          >
            {t('confirm')}
          </LoadingButton>
          <Button onClick={handleClose} autoFocus>
            {t('cancel')}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  )
}

export default ListModal
