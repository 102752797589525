import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import SettingsIcon from '@mui/icons-material/Settings'
import { Box, Button, Card } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  deleteDictionary,
  IDictionaryResponse,
  IPostDictionaryBody,
  postDictionary,
  updateDictionary,
} from 'src/api/requests/dictionaries'
import IconButtonWrapper from 'src/components/buttons/IconButtonWrapper'
import DictionarySettingsForm from 'src/components/forms/DictionarySettingsForm'
import CreateDictionaryModal from 'src/components/modals/CreateDictionaryModal'
import ModalWrapper from 'src/components/modals/ModalWrapper'
import BtnWithConfirm from 'src/components/widgets/BtnWithConfirm'
import DictionaryListSelect from 'src/components/widgets/DictionaryListSelect'
import DictionaryStore from 'src/mobx/dictionary'
import { TEditableDictionary } from 'src/types/Dictionary'

const DictionaryBar = observer(() => {
  const { t } = useTranslation(['dictionaryPage', 'common'])

  const [dictionaryModal, setDictionaryModal] = useState(false)
  const [dictionarySettingsModal, setDictionarySettingsModal] = useState(false)
  const [editableDictionary, setEditableDictionary] = useState<
    TEditableDictionary | undefined
  >(undefined)

  const handleCloseDictionaryModal = () => {
    setEditableDictionary(undefined)
    setDictionaryModal(false)
  }
  const handleOpenDictionaryModal = () => {
    setDictionaryModal(true)
  }

  const handleOpenEditDictionaryModal = () => {
    setEditableDictionary(DictionaryStore.selectedDictionary || undefined)
    setDictionaryModal(true)
  }

  const handleCreateDictionarySubmit = (
    values: IPostDictionaryBody,
  ): Promise<IDictionaryResponse> => {
    if (editableDictionary) {
      const dictionary = DictionaryStore.selectedDictionary
      if (dictionary) {
        return updateDictionary(dictionary.id, values)
          .then((res) => {
            DictionaryStore.updateSelectedDictionary(res.dictionary)
            handleCloseDictionaryModal()
            setEditableDictionary(undefined)
            return res
          })
          .catch((err) => {
            return Promise.reject(err)
          })
      }
    }

    return postDictionary(values)
      .then((res) => {
        DictionaryStore.setDictionaries([
          ...(DictionaryStore.dictionaries ? DictionaryStore.dictionaries : []),
          res.dictionary,
        ])
        DictionaryStore.setSelectedDictionary(res.dictionary, true)
        handleCloseDictionaryModal()
        return res
      })
      .catch((err) => {
        return Promise.reject(err)
      })
  }

  const handleOpenEditDictionarySettingsModal = () => {
    setDictionarySettingsModal(true)
  }
  const handleCloseEditDictionarySettingsModal = () => {
    setDictionarySettingsModal(false)
  }

  const onDelete = async (): Promise<void> => {
    if (DictionaryStore.selectedDictionary) {
      const dictionaryId = DictionaryStore.selectedDictionary.id

      return deleteDictionary(dictionaryId).then(() => {
        DictionaryStore.removeDictionaryById(dictionaryId)
      })
    }
  }

  return (
    <Card
      id="dictionary-bar"
      sx={{
        display: 'flex',
        flexDirection: {
          xs: 'column',
          sm: 'row',
        },
        alignItems: 'center',
        padding: { md: '24px', xs: '8px' },
        marginBottom: '10px',
        borderRadius: '16px',
        gap: '20px',
      }}
    >
      <DictionaryListSelect />

      <Box
        maxWidth="100%"
        display="flex"
        alignItems="center"
        gap={{ md: '20px', xs: '5px' }}
      >
        {DictionaryStore.selectedDictionary && (
          <Box display="flex" alignItems="center" gap="2px">
            <IconButtonWrapper
              icon={EditIcon}
              onClick={handleOpenEditDictionaryModal}
            />

            <IconButtonWrapper
              icon={SettingsIcon}
              onClick={handleOpenEditDictionarySettingsModal}
            />

            <BtnWithConfirm
              icon={DeleteIcon}
              onConfirm={onDelete}
              modalTitle={`${t('delete_dictionary')} "${
                DictionaryStore.selectedDictionary?.title
              }".`}
              iconColorOnHover="error"
            />
          </Box>
        )}

        <Button
          id="add-dictionary-btn"
          variant="contained"
          style={{
            whiteSpace: 'nowrap',
            textTransform: 'none',
            padding: '15px 20px',
            width: '100%',
          }}
          onClick={handleOpenDictionaryModal}
        >
          {t('addDictionary')}
        </Button>
      </Box>

      {/* Create/Edit Dictionary modal */}
      <CreateDictionaryModal
        isOpen={dictionaryModal}
        onClose={handleCloseDictionaryModal}
        title={editableDictionary ? t('edit_dictionary') : t('addDictionary')}
        onSubmit={handleCreateDictionarySubmit}
        preFilledvalues={editableDictionary}
      />

      {/* Edit Dictionary's Premium settings Modal */}
      <ModalWrapper
        isOpen={dictionarySettingsModal}
        onClose={handleCloseEditDictionarySettingsModal}
        maxWidth={500}
        title={t('dictionary_settings', { ns: 'common' })}
      >
        <DictionarySettingsForm />
      </ModalWrapper>
    </Card>
  )
})

export default DictionaryBar
