import { Helmet } from 'react-helmet-async'

const Page = ({ children, title = '', meta }: any) => (
  <>
    <Helmet>
      <title>{`${title} | iVocabulary`}</title>
      {meta}
    </Helmet>

    {children}
  </>
)

export default Page
