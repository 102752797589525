import {
  Alert,
  Box,
  Button,
  Card,
  CircularProgress,
  Container,
  Typography,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { getActivate } from 'src/api/requests/auth'
import Logo from 'src/components/Logo'
import Page from 'src/components/Page'
import useResponsive from 'src/hooks/useResponsive'
import UserStore from 'src/mobx/user'
import { ACCESS_TOKEN_STORE_KEY } from 'src/resources/localStorageKeys'
import { ROUTE_DICTIONARY } from 'src/resources/routes'
import { getError } from 'src/utils/getError'

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}))

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7),
  },
}))

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
}))

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}))

const Activate = observer(() => {
  const [error, setError] = useState<string | null>(null)
  const [loading, setLoading] = useState<boolean>(true)

  const { link } = useParams()
  const { t } = useTranslation(['activatePage', 'error'])
  const navigate = useNavigate()

  const mdUp = useResponsive('up', 'md')

  useEffect(() => {
    if (link) {
      getActivate(link)
        .then((data) => {
          localStorage.setItem(ACCESS_TOKEN_STORE_KEY, data.accessToken)
          UserStore.setUser(data.user)
        })
        .catch((err) => {
          setError(getError(err).message)
        })
        .finally(() => setLoading(false))
    } else {
      setError(t('wrong_link', { ns: 'error' }))
      setLoading(false)
    }
  }, [])

  return (
    <Page title="Activate">
      <Box>
        <RootStyle>
          <HeaderStyle>
            <Logo />
          </HeaderStyle>

          {mdUp && (
            <SectionStyle>
              <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
                {t('title')}
              </Typography>
              <img
                src="/static/illustrations/illustration_login.png"
                alt="login"
              />
            </SectionStyle>
          )}
          {loading ? (
            <Container>
              <ContentStyle>
                <CircularProgress size={100} />
              </ContentStyle>
            </Container>
          ) : UserStore.user ? (
            <Container>
              <ContentStyle>
                <Typography variant="h4" gutterBottom>
                  {t('congratulation')}
                </Typography>
                <Button
                  style={{ textTransform: 'none' }}
                  variant="text"
                  onClick={() => navigate(ROUTE_DICTIONARY, { replace: true })}
                >
                  {t('go_home')}
                </Button>
              </ContentStyle>
            </Container>
          ) : (
            <Container maxWidth="sm">
              <ContentStyle>
                {error && <Alert severity="error">{error}</Alert>}
              </ContentStyle>
            </Container>
          )}
        </RootStyle>
      </Box>
    </Page>
  )
})

export default Activate
