import Page from 'src/components/Page'

const Profile = () => {
  return (
    <Page title="Profile">
      <div>Profile</div>
    </Page>
  )
}

export default Profile
