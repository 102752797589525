import { Box, Container, Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { Link } from 'react-router-dom'
import AccountPopover from 'src/layouts/dashboard/AccountPopover'
import UserStore from 'src/mobx/user'
import { PATH_LOGIN } from 'src/resources/routes'

const PublicDictionaryHeader = observer(() => {
  return (
    <Container>
      {UserStore.user ? (
        <Box
          height={60}
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
        >
          <AccountPopover />
        </Box>
      ) : (
        <Box height={60} display="flex" alignItems="center" gap="10px">
          <Typography color="grey.500" fontWeight={600}>
            You are not logged in.
          </Typography>
          <Box color="gray" bgcolor="info.main" p="2px 7px" borderRadius="5px">
            <Link
              style={{ color: 'white', textDecoration: 'none' }}
              to={`/${PATH_LOGIN}`}
            >
              Login
            </Link>
          </Box>
        </Box>
      )}
    </Container>
  )
})

export default PublicDictionaryHeader
