import { Alert, CircularProgress } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  IDictionaryResponse,
  IPostDictionaryBody,
} from 'src/api/requests/dictionaries'
import { getLanguages } from 'src/api/requests/languages'
import DictionaryForm from 'src/components/forms/DictionaryForm'
import { TEditableDictionary } from 'src/types/Dictionary'
import { ILanguage } from 'src/types/Language'

import ModalWrapper from './ModalWrapper'

interface IProps {
  isOpen: boolean
  onClose: () => void
  title: string
  onSubmit: (value: IPostDictionaryBody) => Promise<IDictionaryResponse>
  preFilledvalues?: TEditableDictionary
}

const CreateDictionaryModal = ({
  isOpen,
  onClose,
  title,
  onSubmit,
  preFilledvalues,
}: IProps) => {
  const { t } = useTranslation(['error'])

  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)

  const [languages, setLanguages] = useState<ILanguage[] | null>(null)

  const handleSubmit = async (
    values: IPostDictionaryBody,
  ): Promise<IDictionaryResponse> => onSubmit(values)

  useEffect(() => {
    if (isOpen && !languages) {
      getLanguages()
        .then((res) => {
          setLanguages(res.languages)
        })
        .catch(() => {
          setError(t('languages_not_downloaded'))
        })
        .finally(() => setLoading(false))
    }
  }, [isOpen])

  return (
    <ModalWrapper
      isOpen={isOpen}
      onClose={onClose}
      title={title}
      maxWidth={400}
    >
      {loading ? (
        <CircularProgress size={100} />
      ) : languages && !error ? (
        <DictionaryForm
          languages={languages}
          onSubmit={handleSubmit}
          btnText={title}
          preFilledvalues={preFilledvalues}
        />
      ) : (
        <Alert severity="error">{error}</Alert>
      )}
    </ModalWrapper>
  )
}
export default CreateDictionaryModal
