import { useState } from 'react'
import { TColors } from 'src/types/Colors'
import { getError } from 'src/utils/getError'

import IconButtonWrapperWithText from '../buttons/IconButtonWrapperWithText'
import ConfirmModal from '../modals/ConfirmModal'

interface IProps {
  onConfirm: () => Promise<void>
  icon: any
  modalTitle: string
  btnText?: string
  iconColorOnHover?: TColors
}

const BtnWithConfirm = ({
  onConfirm,
  icon,
  modalTitle,
  btnText,
  iconColorOnHover,
}: IProps) => {
  const [isOpenConfirm, setIsOpenConfirm] = useState(false)
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [confirmError, setConfirmError] = useState<string | null>(null)

  const handleConfirm = async () => {
    setConfirmError(null)
    setConfirmLoading(true)
    await onConfirm()
      .then(() => closeConfirm())
      .catch((err) => setConfirmError(getError(err).message))
      .finally(() => setConfirmLoading(false))
  }

  const openConfirmModal = () => {
    setIsOpenConfirm(true)
  }
  const closeConfirm = () => {
    setConfirmError(null)
    setIsOpenConfirm(false)
  }

  return (
    <>
      <IconButtonWrapperWithText
        onClick={openConfirmModal}
        icon={icon}
        text={btnText}
        iconColorOnHover={iconColorOnHover}
      />
      <ConfirmModal
        open={isOpenConfirm}
        handleClose={closeConfirm}
        onConfirm={handleConfirm}
        loading={confirmLoading}
        title={modalTitle}
        error={confirmError}
      />
    </>
  )
}

export default BtnWithConfirm
