import { LoadingButton } from '@mui/lab'
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface IProps {
  open: boolean
  title?: string
  contentText?: string
  handleClose: () => void
  onConfirm: () => void
  loading?: boolean
  error?: string | null
}

const ConfirmModal = ({
  open,
  handleClose,
  onConfirm,
  contentText,
  title,
  loading,
  error,
}: IProps) => {
  const { t } = useTranslation(['common'])

  const handleEnter = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') onConfirm()
  }
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      onKeyDown={handleEnter}
    >
      <Box sx={{ width: { sm: 400, xs: '100%' } }}>
        <DialogTitle id="responsive-dialog-title">
          {title || t('are_you_sure')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{contentText}</DialogContentText>
          {error && !loading && <Alert severity="error">{error}</Alert>}
        </DialogContent>
        <DialogActions
          sx={{ display: 'flex', justifyContent: 'center', gap: '40px' }}
        >
          <LoadingButton
            variant="contained"
            loading={loading}
            onClick={onConfirm}
          >
            Confirm
          </LoadingButton>
          <Button onClick={handleClose} autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  )
}

export default ConfirmModal
