import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove'
import { useState } from 'react'
import IconButtonWrapperWithText from 'src/components/buttons/IconButtonWrapperWithText'
import { IDictionary } from 'src/types/Dictionary'
import { getError } from 'src/utils/getError'

import { ISelectOption } from '../inputs/SelectWithFilter'
import ListModal from '../modals/ListModal'

interface IProps {
  onMove: (dictionaryId: number) => Promise<void>
  dictionaries: IDictionary[]
  selectedDictionary: IDictionary
  btnText?: string
}

const MoveBtnWithDictionarySelect = ({
  onMove,
  dictionaries,
  selectedDictionary,
  btnText,
}: IProps) => {
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [moveLoading, setMoveLoading] = useState(false)
  const [moveError, setMoveError] = useState<string | null>(null)

  const handleMove = async (dictionaryId: number) => {
    setMoveError(null)
    setMoveLoading(true)
    await onMove(dictionaryId)
      .then(() => closeModal())
      .catch((err) => setMoveError(getError(err).message))
      .finally(() => setMoveLoading(false))
  }

  const selectOptions: ISelectOption[] = dictionaries.map((d) => ({
    value: d.id,
    label: d.title,
  }))

  const openModal = () => {
    setIsOpenModal(true)
  }

  const closeModal = () => {
    setMoveError(null)
    setIsOpenModal(false)
  }

  return (
    <>
      <IconButtonWrapperWithText
        onClick={openModal}
        icon={DriveFileMoveIcon}
        text={btnText}
      />
      <ListModal
        open={isOpenModal}
        handleClose={closeModal}
        onConfirm={handleMove}
        selectOptions={selectOptions}
        loading={moveLoading}
        error={moveError}
        value={selectedDictionary.id}
      />
    </>
  )
}

export default MoveBtnWithDictionarySelect
