import { Box, Typography } from '@mui/material'
import { IWordForm } from 'src/types/WordForm'

interface IFormItemProps {
  wordForm: IWordForm
}

export const WordFormItem = ({ wordForm }: IFormItemProps) => {
  return (
    <Box sx={{ marginBottom: '5px' }}>
      <Box display="flex" gap="10px" fontSize="14px">
        <Box width="35%">
          <Typography sx={{ overflowWrap: 'break-word', fontWeight: 600 }}>
            {wordForm.form}
          </Typography>
        </Box>
        <Box width="65%">
          {wordForm.description && (
            <Typography
              sx={{
                color: 'grey.800',
                overflowWrap: 'break-word',
              }}
            >
              - {wordForm.description}
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  )
}
