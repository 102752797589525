import CloseIcon from '@mui/icons-material/Close'
import { Box, IconButton, Modal, Stack, Typography } from '@mui/material'

const style = {
  position: 'relative',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  maxHeight: '100%',
  overflowY: 'auto',
  bgcolor: 'background.paper',
  border: '2px solid gray',
  borderRadius: '8px',
  boxShadow: 24,
  p: { sm: 4, xs: 1 },
}

interface IProps {
  isOpen: boolean
  onClose: () => void
  title?: string
  children?: JSX.Element | JSX.Element[]
  maxWidth?: number
  onEnterPressed?: () => void
}

const ModalWrapper = ({
  isOpen,
  onClose,
  title,
  maxWidth = 600,
  children,
  onEnterPressed,
}: IProps) => {
  const onKeyDown = (e: React.KeyboardEvent<HTMLElement>): void => {
    if (e.key === 'Enter' && onEnterPressed) onEnterPressed()
  }

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      sx={{ margin: { sm: '0 10px', xs: '0' } }}
      onKeyDown={onKeyDown}
    >
      <Box sx={style} maxWidth={maxWidth} position="relative" overflow="hidden">
        <Box
          sx={{
            position: 'absolute',
            top: '0',
            right: { sm: '0', xs: '-3px' },
          }}
          onClick={() => onClose()}
        >
          <IconButton>
            <CloseIcon />
          </IconButton>
        </Box>

        <Stack spacing={2}>
          {title && (
            <Typography
              variant="h6"
              component="h2"
              marginBottom={2}
              marginRight={{ xs: '20px', sm: '10px' }}
            >
              {title}
            </Typography>
          )}

          {children}
        </Stack>
      </Box>
    </Modal>
  )
}
export default ModalWrapper
