import { Box, Typography } from '@mui/material'
import { IWordUsageExample } from 'src/types/WordUsageExample'

interface IExampleItemProps {
  example: IWordUsageExample
  hideUnderline: boolean
}

export const WordUsageExampleItem = ({
  example,
  hideUnderline,
}: IExampleItemProps) => {
  return (
    <Box
      sx={{
        borderBottom: hideUnderline ? 0 : 1,
        borderColor: 'grey.400',
        marginBottom: '10px',
      }}
    >
      <Box mb={1}>
        <Typography
          sx={{
            fontSize: '16px',
            overflowWrap: 'break-word',
            fontWeight: example.isBold ? 600 : 400,
          }}
        >
          {example.sentence}
        </Typography>
        {example.translation && (
          <Typography
            sx={{
              fontSize: '13px',
              color: 'grey.400',
              overflowWrap: 'break-word',
              fontWeight: example.isBold ? 600 : 400,
            }}
          >
            {example.translation}
          </Typography>
        )}
      </Box>
    </Box>
  )
}
