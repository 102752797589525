import { Box, IconButton, MenuItem, Stack } from '@mui/material'
import { alpha } from '@mui/material/styles'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import MenuPopover from 'src/components/MenuPopover'
import { Locale } from 'src/types/Locale'

interface ILang {
  value: Locale
  label: string
  icon: string
  id: number
}

const LANGS: ILang[] = [
  {
    value: 'en' as Locale,
    label: 'English',
    icon: '/static/icons/ic_flag_en.svg',
    id: 0,
  },
  {
    value: 'ru' as Locale,
    label: 'Русский',
    icon: '/static/icons/ic_flag_ru.svg',
    id: 1,
  },
]

const LanguagePopover = () => {
  const anchorRef = useRef(null)
  const [open, setOpen] = useState(false)
  const [activeItem, setActiveItem] = useState<ILang>()

  const { i18n } = useTranslation()

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleChangeLang = (lang: Locale) => {
    handleClose()
    changeLang(lang)
  }

  const changeLang = (lang: Locale | null) => {
    let langExist: ILang | null = null

    lang &&
      LANGS.map((item) => {
        if (item.value === lang) {
          langExist = item
        }
      })

    if (!langExist) {
      langExist = LANGS[0]
    }

    i18n.changeLanguage(langExist.value)
    setActiveItem(langExist)
  }

  useEffect(() => {
    const language = localStorage.getItem('i18nextLng') as Locale

    changeLang(language)
  }, [])

  return (
    <>
      {activeItem && (
        <>
          <IconButton
            ref={anchorRef}
            onClick={handleOpen}
            sx={{
              padding: 0,
              width: 44,
              height: 44,
              ...(open && {
                bgcolor: (theme) =>
                  alpha(
                    theme.palette.primary.main,
                    theme.palette.action.focusOpacity,
                  ),
              }),
            }}
          >
            <img src={activeItem.icon} alt={activeItem.label} />
          </IconButton>

          <MenuPopover
            open={open}
            onClose={handleClose}
            anchorEl={anchorRef.current}
            sx={{
              mt: 1.5,
              ml: 0.75,
              width: 180,
              '& .MuiMenuItem-root': {
                px: 1,
                typography: 'body2',
                borderRadius: 0.75,
              },
            }}
          >
            <Stack spacing={0.75}>
              {LANGS.map((option) => (
                <MenuItem
                  key={option.value}
                  selected={option.value === activeItem.value}
                  onClick={() => handleChangeLang(option.value)}
                >
                  <Box
                    component="img"
                    alt={option.label}
                    src={option.icon}
                    sx={{ width: 28, mr: 2 }}
                  />

                  {option.label}
                </MenuItem>
              ))}
            </Stack>
          </MenuPopover>
        </>
      )}
    </>
  )
}

export default LanguagePopover
