import SearchIcon from '@mui/icons-material/Search'
import {
  Box,
  FormControl,
  InputAdornment,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { containsText } from 'src/utils/containText'

export interface ISelectOption {
  label: string
  value: any
}

interface IProps {
  onChange: (value: any) => void
  options: ISelectOption[]
  value?: any
  label?: string
}

const SelectWithFilter = ({ value, onChange, options, label }: IProps) => {
  const { t } = useTranslation('common')
  const [searchText, setSearchText] = useState('')

  const displayedOptions = useMemo(
    () => options.filter((option) => containsText(option.label, searchText)),
    [searchText, options],
  )

  return (
    <Box
      sx={{
        width: {
          xs: '100%',
          sm: '350px',
        },
      }}
    >
      <FormControl sx={{ width: '100%' }}>
        <InputLabel id="search-select-label">{label}</InputLabel>
        <Select
          MenuProps={{ autoFocus: false }}
          labelId="search-select-label"
          value={value}
          label={label}
          onChange={(e) => onChange(e.target.value)}
          onClose={() => setSearchText('')}
        >
          <ListSubheader sx={{ width: '250px' }}>
            <TextField
              size="small"
              className="search-input"
              placeholder={t('type_to_search')}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => setSearchText(e.target.value)}
              onKeyDown={(e) => {
                if (e.key !== 'Escape') {
                  e.stopPropagation()
                }
              }}
            />
          </ListSubheader>

          {displayedOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  )
}

export default SelectWithFilter
