import styled from '@emotion/styled'
import { Box, IconButton, Typography } from '@mui/material'
import { TColors } from 'src/types/Colors'

const StyledBox = styled(Box)(
  ({ theme, iconColorOnHover = 'success' }: any) => ({
    display: 'flex',
    gap: '5px',
    alignItems: 'center',
    transition: '.2s',
    cursor: 'pointer',
    flexGrow: 1,
    '&:hover div': {
      color: theme.palette[iconColorOnHover].main,
    },
  }),
)

const StyledIconBtn = styled(IconButton)(
  ({ theme, coloronhover = 'success' }: any) => ({
    transition: '.2s',
    '&:hover': {
      color: theme.palette[coloronhover].main,
    },
  }),
)

interface IProps {
  onClick: () => void
  icon: any
  text?: string
  iconColorOnHover?: TColors
  dataTestAttr?: string
}

const IconButtonWrapperWithText = ({
  onClick,
  icon: Icon,
  text,
  iconColorOnHover,
  dataTestAttr,
}: IProps) => {
  return (
    <StyledBox
      iconColorOnHover={iconColorOnHover}
      data-test={dataTestAttr}
      onClick={onClick}
    >
      {text ? (
        <Box padding="7px 10px" display="flex" alignItems="center">
          <Icon />
        </Box>
      ) : (
        <StyledIconBtn coloronhover={iconColorOnHover}>
          <Icon />
        </StyledIconBtn>
      )}

      {text && <Typography>{text}</Typography>}
    </StyledBox>
  )
}

export default IconButtonWrapperWithText
