import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn'
import { Box, Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import useEventListener from 'src/hooks/useEventListener'

interface IProps {
  onClick: () => void
  label?: string
  isDisabled?: boolean
}

const StartButton = ({ onClick, label, isDisabled }: IProps) => {
  const { t } = useTranslation(['common'])

  const onKeyboardDown = (e: KeyboardEvent) => {
    e.stopPropagation()
    if (e.key === 'Enter' && !isDisabled) onClick()
  }

  useEventListener('keydown', onKeyboardDown)

  return (
    <Button
      sx={{
        marginTop: '20px',
        width: {
          sm: '350px',
          xs: '100%',
        },
      }}
      color="success"
      variant="contained"
      disabled={isDisabled}
      onClick={onClick}
    >
      {label || t('start')}

      <Box
        component="span"
        ml="10px"
        display={{ md: 'flex', xs: 'none' }}
        alignItems="center"
      >
        <KeyboardReturnIcon />
      </Box>
    </Button>
  )
}

export default StartButton
