import { Checkbox, TableCell, TableHead, TableRow } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { ChangeEvent } from 'react'
import DictionaryStore from 'src/mobx/dictionary'

interface IProps {
  rowCount: number
  headLabel: any[]
  numSelected: number
  setSelected: (s: number[]) => void
}

const WordListHead = observer(
  ({ rowCount, headLabel, numSelected, setSelected }: IProps) => {
    const onSelectAllClick = (event: ChangeEvent<HTMLInputElement>) => {
      if (
        event.target.checked &&
        DictionaryStore.selectedDictionaryWords &&
        DictionaryStore.otherDictionaryWords
      ) {
        const newSelecteds = [
          ...DictionaryStore.selectedDictionaryWords.map((n) => n.id),
          ...DictionaryStore.otherDictionaryWords.map((n) => n.id),
        ]
        setSelected(newSelecteds)
        return
      }
      setSelected([])
    }

    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
            />
          </TableCell>
          {headLabel.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.alignRight ? 'right' : 'left'}
            >
              {headCell.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    )
  },
)

export default WordListHead
