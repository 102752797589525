export const ApiRoutes = {
  postUser: 'v1/users',
  getWhoAmI: 'v1/users/who-am-i',
  patchStartRestoration: 'v1/users/start-password-restorarion',
  patchFinishRestoration: 'v1/users/finish-password-restorarion',
  postUserReport: 'v1/users/reports',

  postLogin: 'v1/auth/login',
  getActivate: 'v1/auth/activate',
  getRefresh: 'v1/auth/refresh',
  getSignout: 'v1/auth/signout',
  postGoogleAuth: 'v1/auth/google',

  postDictionary: 'v1/dictionaries',
  putDictionary: (id: number) => `v1/dictionaries/${id}`,
  getPublicDictionary: (id: number) => `v1/dictionaries/${id}/public`,
  patchDictionaryPublicStatus: (id: number) => `v1/dictionaries/${id}/public`,
  patchDictionarySettings: (id: number) => `v1/dictionaries/${id}/settings`,
  getDictionaries: 'v1/dictionaries',
  deleteDictionary: (id: number) => `v1/dictionaries/${id}`,

  getLanguages: 'v1/languages',

  getDictionaryWords: (dictionaryId: number) =>
    `v1/words/dictionary/${dictionaryId}`,
  postWord: 'v1/words',
  deleteManyWords: 'v1/words',
  updateWord: (id: number) => `v1/words/${id}`,
  updateWordsTrainingLevel: 'v1/words/trainingLevel',
  updateWordsDictionary: 'v1/words/dictionary-change',
  // Word Usage Examples
  postWordUsageExample: 'v1/word-usage-examples',
  getWordUsageExamples: (wordId: number) =>
    `v1/word-usage-examples/word/${wordId}`,
  getPublicWordUsageExamples: (wordId: number) =>
    `v1/word-usage-examples/word/${wordId}/public`,
  updateWordUsageExample: (id: number) => `v1/word-usage-examples/${id}`,
  deleteManyWordUsageExamples: (wordId: number) =>
    `v1/word-usage-examples/word/${wordId}`,
  // Word Forms
  postWordForm: 'v1/word-forms',
  getWordForms: (wordId: number) => `v1/word-forms/word/${wordId}`,
  getPublicWordForms: (wordId: number) => `v1/word-forms/word/${wordId}/public`,
  updateWordForm: (id: number) => `v1/word-forms/${id}`,
  deleteManyWordForms: (wordId: number) => `v1/word-forms/word/${wordId}`,
}
