import { Box, Checkbox, TableCell, TableRow } from '@mui/material'
import { useTranslation } from 'react-i18next'
import ChargeLevel from 'src/components/common/ChargeLevel'
import { IWord } from 'src/types/Word'

import WordMoreMenu from './WordMoreMenu'

interface IProps {
  item: IWord
  selected: number[]
  setSelected: (w: number[]) => void
  handleOpenWordModal: (w: IWord) => void
  showTranscription: boolean
}

const WordRow = ({
  item,
  selected,
  setSelected,
  handleOpenWordModal,
  showTranscription,
}: IProps) => {
  const { t } = useTranslation(['dictionaryPage'])

  const { id, word, translation, transcription, trainingLevel } = item
  const isItemSelected = selected.indexOf(id) !== -1

  const onCheckboxClick = (id: number) => {
    const selectedIndex = selected.indexOf(id)
    let newSelected: number[] = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      )
    }
    setSelected(newSelected)
  }

  return (
    <TableRow
      hover
      tabIndex={-1}
      role="checkbox"
      selected={isItemSelected}
      aria-checked={isItemSelected}
      onClick={() => {
        handleOpenWordModal(item)
      }}
    >
      <TableCell padding="checkbox">
        <Checkbox
          checked={isItemSelected}
          onClick={(e) => e.stopPropagation()}
          onChange={() => onCheckboxClick(id)}
        />
      </TableCell>
      <TableCell align="left" sx={{ cursor: 'pointer' }}>
        {word}
      </TableCell>
      <TableCell align="left" sx={{ cursor: 'pointer' }}>
        {translation}
      </TableCell>
      {showTranscription && (
        <TableCell align="left" sx={{ cursor: 'pointer' }}>
          {transcription}
        </TableCell>
      )}
      <TableCell align="left"></TableCell>

      <TableCell onClick={(e) => e.stopPropagation()}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          <ChargeLevel
            title={t('training_level')}
            chargeLevel={trainingLevel}
          />
          <WordMoreMenu wordItem={item} />
        </Box>
      </TableCell>
    </TableRow>
  )
}

export default WordRow
