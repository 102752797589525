import Battery0BarIcon from '@mui/icons-material/Battery0Bar'
import CancelIcon from '@mui/icons-material/Cancel'
import DeleteIcon from '@mui/icons-material/Delete'
import SearchIcon from '@mui/icons-material/Search'
import TextRotateUpIcon from '@mui/icons-material/TextRotateUp'
import TextRotateVerticalIcon from '@mui/icons-material/TextRotateVertical'
import { LoadingButton } from '@mui/lab'
import {
  Alert,
  Box,
  Button,
  InputAdornment,
  InputLabel,
  Menu,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Toolbar,
  Typography,
} from '@mui/material'
import FormControl from '@mui/material/FormControl'
import { styled } from '@mui/material/styles'
import debounce from 'lodash.debounce'
import { observer } from 'mobx-react-lite'
import { ChangeEvent, useCallback, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  deleteManyWords,
  postWord,
  updateWordsDictionary,
  updateWordsTrainingLevel,
} from 'src/api/requests/words'
import { WordSortBy } from 'src/api/types/WordSortBy'
import { WordSortDirection } from 'src/api/types/WordSortDirection'
import IconButtonWrapper from 'src/components/buttons/IconButtonWrapper'
import BtnWithConfirm from 'src/components/widgets/BtnWithConfirm'
import MoveBtnWithDictionarySelect from 'src/components/widgets/MoveBtnWithDictionarySelect'
import useResponsive from 'src/hooks/useResponsive'
import DictionaryStore from 'src/mobx/dictionary'
import { getError } from 'src/utils/getError'

const RootStyle = styled(Toolbar)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(3),
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(1),
  },
}))

const SearchStyle = styled(OutlinedInput)(({ theme }: any) => ({
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  [theme.breakpoints.up('xs')]: {
    width: '100%',
  },
  [theme.breakpoints.up('sm')]: {
    width: 350,
    '&.Mui-focused': { width: 360, boxShadow: theme.customShadows.z8 },
  },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}))

interface IProps {
  selected: number[]
  setSelected: (s: number[]) => void
}

const WordListToolbar = observer(({ selected, setSelected }: IProps) => {
  const { t } = useTranslation(['dictionaryPage', 'common', 'error'])
  const smUp = useResponsive('up', 'sm')

  const [openAddWordMenu, setOpenAddWordMenu] = useState(false)
  const [isBtnDisable, setIsBtnDisable] = useState(true)
  const [postWordLoading, setPostWordLoading] = useState(false)
  const [postWordError, setPostWordError] = useState<string | null>(null)

  const anchorRef = useRef<null | HTMLDivElement>(null)
  const anchorInputRef = useRef<null | HTMLInputElement>(null)
  const dropdownWordInputRef = useRef<null | HTMLInputElement>(null)
  const dropdownTranslationInputRef = useRef<null | HTMLInputElement>(null)

  const onChangeSecondSearchInput = (e: ChangeEvent<HTMLInputElement>) => {
    const anchorInput = anchorInputRef.current
    // trigger anchorInput onChange event
    if (anchorInput) {
      const lastValue = anchorInput.value
      anchorInput.value = e.target.value
      const event = new Event('input', { bubbles: true })
      // @ts-ignore
      const tracker = anchorInput._valueTracker
      if (tracker) {
        tracker.setValue(lastValue)
      }
      anchorInput.dispatchEvent(event)
    }

    handleCloseDropDown()

    setTimeout(() => {
      if (anchorInput) anchorInput.focus()
    }, 100)
  }

  const handleCloseDropDown = () => {
    setOpenAddWordMenu(false)
    setIsBtnDisable(true)
    setPostWordError(null)
  }

  const debouncedHandleSearch = useCallback(
    debounce(async function (value: string) {
      if (DictionaryStore.selectedDictionary && anchorInputRef.current) {
        DictionaryStore.setWordListPage(1)
        await DictionaryStore.getDictionaryWords(
          DictionaryStore.selectedDictionary.id,
          { filter: value },
        )
      }
    }, 1000),
    [],
  )

  const handleOpenDropDown = (e?: any) => {
    if (
      (e && e.key && e.key !== 'Enter') ||
      DictionaryStore.filterWord.length < 1
    )
      return

    setOpenAddWordMenu(true)
    setTimeout(() => {
      if (dropdownTranslationInputRef.current)
        dropdownTranslationInputRef.current.focus()
    }, 100)
  }

  const onTranslationChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length > 0) {
      setIsBtnDisable(false)
    } else {
      setIsBtnDisable(true)
    }
  }

  const handleSubmit = (e: any) => {
    if (
      (e && e.key && e.key !== 'Enter') ||
      isBtnDisable ||
      DictionaryStore.wordLoading
    )
      return

    const wordInput = dropdownWordInputRef.current
    const translationInput = dropdownTranslationInputRef.current

    if (wordInput && translationInput && DictionaryStore.selectedDictionary) {
      if (
        DictionaryStore.selectedDictionaryWords &&
        DictionaryStore.selectedDictionaryWords.find(
          (item) => item.word === wordInput.value,
        )
      ) {
        setPostWordError(t('word_is_added', { ns: 'error' }))
        return
      }

      setPostWordLoading(true)
      setPostWordError(null)
      postWord({
        word: wordInput.value,
        translation: translationInput.value,
        dictionaryId: DictionaryStore.selectedDictionary.id,
      })
        .then((res) => {
          DictionaryStore.addWord(res.word)
          handleCloseDropDown()
        })
        .catch((err) => setPostWordError(getError(err).message))
        .finally(() => setPostWordLoading(false))
    }
  }

  const onDeleteSelected = async () => {
    await deleteManyWords(selected).then(() => {
      DictionaryStore.removeWordsById(selected)
      setSelected([])
    })
  }

  const onUnchargeSelectedWords = async () => {
    const updateObjects = selected.map((id) => ({
      id,
      trainingLevel: 0,
      dictionaryId: DictionaryStore.selectedDictionary?.id as number,
    }))

    await updateWordsTrainingLevel(updateObjects).then(() => {
      selected.forEach((wordId) => {
        const word = DictionaryStore.getWordById(wordId)

        if (word) DictionaryStore.updateWord({ ...word, trainingLevel: 0 })
      })
    })
  }

  const onMoveSelected = async (dictionaryId: number) => {
    await updateWordsDictionary({ wordIds: selected, dictionaryId }).then(
      () => {
        DictionaryStore.removeWordsById(selected)
        setSelected([])
      },
    )
  }

  const handleChangeSortBy = (e: SelectChangeEvent) => {
    DictionaryStore.onChangeSortBy(e.target.value as WordSortBy)
  }

  const handleChangeSortDirection = () => {
    DictionaryStore.toggleSortDirection()
  }

  return (
    <RootStyle
      sx={{
        ...(!!selected.length && {
          color: 'primary.main',
          bgcolor: 'primary.lighter',
        }),
      }}
    >
      {selected.length > 0 ? (
        <Typography component="div" variant="subtitle1">
          {selected.length} {t('selected')}
        </Typography>
      ) : (
        <Box
          display="flex"
          alignItems="center"
          width="100%"
          justifyContent="space-between"
          flexDirection={{ xs: 'column', sm: 'row' }}
          gap={{ xs: '17px', sm: '0' }}
        >
          <Box
            display="flex"
            alignItems="center"
            width={{ xs: '100%', sm: 'calc(100% - 200px)' }}
            gap="20px"
          >
            <SearchStyle
              ref={anchorRef}
              inputRef={anchorInputRef}
              value={DictionaryStore.filterWord}
              onChange={(e) => {
                DictionaryStore.setWordLoading(true)
                debouncedHandleSearch(e.target.value)
                DictionaryStore.setFilterWord(e.target.value)
              }}
              placeholder={t('search', { ns: 'common' })}
              onKeyDown={handleOpenDropDown}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
              endAdornment={
                <>
                  {DictionaryStore.filterWord && (
                    <IconButtonWrapper
                      icon={CancelIcon}
                      onClick={() => {
                        DictionaryStore.setWordLoading(true)
                        debouncedHandleSearch('')
                        DictionaryStore.setFilterWord('')
                      }}
                    />
                  )}
                </>
              }
            />
            {DictionaryStore.filterWord.length > 0 && (
              <Box maxWidth="100%">
                <Button
                  aria-controls={openAddWordMenu ? 'add-word-menu' : undefined}
                  variant="contained"
                  style={{
                    whiteSpace: 'nowrap',
                    textTransform: 'none',
                    padding: '15px 20px',
                  }}
                  onClick={handleOpenDropDown}
                >
                  {t('add', { ns: 'common' })}
                </Button>
              </Box>
            )}
            <Menu
              id="add-word-menu"
              anchorEl={anchorRef.current}
              open={openAddWordMenu}
              onClose={handleCloseDropDown}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              PaperProps={{ style: { width: smUp ? 370 : '90%' } }}
            >
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: '10px',
                  padding: '0 10px',
                  textAlign: 'center',
                }}
              >
                <SearchStyle
                  value={DictionaryStore.filterWord}
                  inputRef={dropdownWordInputRef}
                  onChange={onChangeSecondSearchInput}
                  placeholder={t('search', { ns: 'common' })}
                  startAdornment={
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  }
                />
                <Typography color="lightgray">
                  {t('no_translator')}
                  <br /> {t('enter_translation')}
                </Typography>
                <SearchStyle
                  inputRef={dropdownTranslationInputRef}
                  placeholder={t('translation', { ns: 'common' })}
                  onChange={onTranslationChange}
                  onKeyDown={handleSubmit}
                />

                {postWordError && (
                  <Alert severity="error" sx={{ width: '100%' }}>
                    {postWordError}
                  </Alert>
                )}

                <LoadingButton
                  variant="contained"
                  sx={{ width: '100%' }}
                  loading={postWordLoading || DictionaryStore.wordLoading}
                  onClick={handleSubmit}
                  disabled={isBtnDisable}
                >
                  {t('add_word')}
                </LoadingButton>
              </Box>
            </Menu>
          </Box>

          <Box
            width={{ xs: '100%', sm: 'auto' }}
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            gap="13 px"
          >
            <Box>
              <IconButtonWrapper
                onClick={handleChangeSortDirection}
                icon={
                  DictionaryStore.sortDirection === WordSortDirection.ASC
                    ? TextRotateVerticalIcon
                    : TextRotateUpIcon
                }
              />
            </Box>

            <Box width="150px">
              <FormControl fullWidth>
                <InputLabel id="sort-by-select-label">SortBy</InputLabel>
                <Select
                  labelId="sort-by-select-label"
                  id="sort-by-select"
                  value={DictionaryStore.sortBy}
                  label="SortBy"
                  onChange={handleChangeSortBy}
                >
                  <MenuItem value={WordSortBy.word}>Word</MenuItem>
                  <MenuItem value={WordSortBy.translation}>
                    Translation
                  </MenuItem>
                  <MenuItem value={WordSortBy.createdAt}>Created At</MenuItem>
                  <MenuItem value={WordSortBy.trainingLevel}>
                    Training Level
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Box>
        </Box>
      )}

      {selected.length > 0 && (
        <Box style={{ display: 'flex' }}>
          {DictionaryStore.selectedDictionary && DictionaryStore.dictionaries && (
            <Box>
              <MoveBtnWithDictionarySelect
                onMove={onMoveSelected}
                dictionaries={DictionaryStore.dictionaries}
                selectedDictionary={DictionaryStore.selectedDictionary}
              />
            </Box>
          )}
          <Box>
            <BtnWithConfirm
              icon={Battery0BarIcon}
              onConfirm={onUnchargeSelectedWords}
              modalTitle={t('are_you_sure', { ns: 'common' })}
              iconColorOnHover="error"
            />
          </Box>
          <Box>
            <BtnWithConfirm
              icon={DeleteIcon}
              onConfirm={onDeleteSelected}
              modalTitle={`${t('delete_words')} - ${selected.length}.`}
              iconColorOnHover="error"
            />
          </Box>
        </Box>
      )}
    </RootStyle>
  )
})

export default WordListToolbar
