import SwapHorizIcon from '@mui/icons-material/SwapHoriz'
import { LoadingButton } from '@mui/lab'
import {
  Alert,
  Box,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material'
import { Form, FormikProvider, useFormik } from 'formik'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  IDictionaryResponse,
  IPostDictionaryBody,
} from 'src/api/requests/dictionaries'
import { TEditableDictionary } from 'src/types/Dictionary'
import { ILanguage } from 'src/types/Language'
import { getError } from 'src/utils/getError'
import * as Yup from 'yup'

interface IProps {
  preFilledvalues?: TEditableDictionary
  btnText?: string
  languages: ILanguage[]
  onSubmit: (values: IPostDictionaryBody) => Promise<IDictionaryResponse>
}

const selectStyle = { width: { sx: '120px', xs: '100%' }, minWidth: '120px' }

const DictionaryForm = ({
  preFilledvalues,
  btnText,
  languages,
  onSubmit,
}: IProps) => {
  const defaultFromLang =
    (preFilledvalues &&
      languages.find((l) => l.id === preFilledvalues.fromLanguageId)) ||
    languages[0]
  const defaultToLang =
    (preFilledvalues &&
      languages.find((l) => l.id === preFilledvalues.toLanguageId)) ||
    languages[1]

  const { t } = useTranslation(['common', 'error'])

  const [error, setError] = useState<string | null>(null)

  const validationSchema = Yup.object().shape({
    title: Yup.string().min(1).max(30).required(),
    fromLanguageId: Yup.number().required(),
    toLanguageId: Yup.number().required(),
  })

  const formik = useFormik({
    initialValues: {
      title: (preFilledvalues && preFilledvalues.title) || '',
      fromLanguageId: defaultFromLang.id,
      toLanguageId: defaultToLang.id,
    },
    validationSchema,
    onSubmit: () => {
      setError(null)
      onSubmit(values)
        .catch((err) => setError(getError(err).message))
        .finally(() => setSubmitting(false))
    },
  })

  const {
    errors,
    touched,
    values,
    isSubmitting,
    setSubmitting,
    handleSubmit,
    getFieldProps,
    setValues,
  } = formik

  const swap = () => {
    setValues({
      ...values,
      fromLanguageId: values.toLanguageId,
      toLanguageId: values.fromLanguageId,
    })
  }

  const handleChangeSelect = (e: SelectChangeEvent) => {
    const { target: tar } = e

    let newValues = {
      ...values,
      [tar.name]: +tar.value,
    }
    if (tar.name === 'fromLanguageId' && +tar.value === values.toLanguageId)
      newValues = {
        ...values,
        fromLanguageId: +tar.value,
        toLanguageId: values.fromLanguageId,
      }

    if (tar.name === 'toLanguageId' && +tar.value === values.fromLanguageId)
      newValues = {
        ...values,
        toLanguageId: +tar.value,
        fromLanguageId: values.toLanguageId,
      }

    setValues(newValues)
  }

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
          <Box
            display="flex"
            justifyContent="space-between"
            flexDirection={{ xs: 'column', sm: 'row' }}
          >
            <FormControl>
              <InputLabel id="from-lang-label">{t('language')}</InputLabel>
              <Select
                sx={selectStyle}
                labelId="from-lang-label"
                id="from-lang-input"
                label={t('language')}
                {...getFieldProps('fromLanguageId')}
                value={values.fromLanguageId.toString()}
                onChange={handleChangeSelect}
              >
                {languages.length &&
                  languages.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.lang}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>

            <Box>
              <IconButton
                style={{ display: 'flex', margin: '4px auto' }}
                onClick={swap}
              >
                <SwapHorizIcon />
              </IconButton>
            </Box>

            <FormControl>
              <InputLabel id="to-lang-label">{t('language')}</InputLabel>
              <Select
                sx={selectStyle}
                labelId="to-lang-label"
                id="to-lang-input"
                label={t('language')}
                {...getFieldProps('toLanguageId')}
                value={values.toLanguageId.toString()}
                onChange={handleChangeSelect}
              >
                {languages.length &&
                  languages.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.lang}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Box>

          <TextField
            id="dictionary-title-input"
            fullWidth
            label={t('title')}
            {...getFieldProps('title')}
            error={Boolean(touched.title && errors.title)}
            helperText={touched.title && errors.title}
          />

          {error && !isSubmitting && <Alert severity="error">{error}</Alert>}

          <LoadingButton
            id="create-dictionary-submit-btn"
            fullWidth
            sx={{ marginTop: 2 }}
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            {btnText || t('confirm')}
          </LoadingButton>
        </Box>
      </Form>
    </FormikProvider>
  )
}

export default DictionaryForm
