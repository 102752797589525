import { LoadingButton } from '@mui/lab'
import {
  Alert,
  Box,
  Button,
  OutlinedInput,
  styled,
  Typography,
} from '@mui/material'
import { Form, FormikProvider, useFormik } from 'formik'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IWordForm } from 'src/types/WordForm'
import { getError } from 'src/utils/getError'
import * as Yup from 'yup'

const Input = styled(OutlinedInput)(() => ({
  padding: '7px',
  minHeight: '35px',
  height: '50px',
  width: '100%',
  resize: 'none',
  border: '1px solid gray',
  borderRadius: '5px',
}))

interface IFormItemProps {
  wordForm: IWordForm
  onFormEdit: (wordForm: IWordForm) => Promise<void>
  onFormDelete: (wordForm: number) => void
  somethingIsEditing: boolean
  setSomethingIsEditing: (b: boolean) => void
}

export const WordFormItem = ({
  wordForm,
  onFormEdit,
  onFormDelete,
  somethingIsEditing,
  setSomethingIsEditing,
}: IFormItemProps) => {
  const { t } = useTranslation(['common', 'dictionaryPage'])

  const [edit, setEdit] = useState(false)
  const [predelete, setPredelete] = useState(false)
  const [error, setError] = useState<string | null>(null)

  const Schema = Yup.object().shape({
    form: Yup.string().min(1).max(20).required(),
    description: Yup.string().max(35).optional(),
  })

  const formik = useFormik({
    initialValues: {
      form: wordForm.form,
      description: wordForm.description || '',
    },
    validationSchema: Schema,
    onSubmit: () => {
      Methods.onEdit()
    },
  })

  const {
    errors,
    touched,
    values,
    isSubmitting,
    setSubmitting,
    handleSubmit,
    getFieldProps,
  } = formik

  // class because it is simple to roll up
  class Methods {
    static onEdit() {
      onFormEdit({
        ...wordForm,
        form: values.form,
        description: values.description,
      })
        .then(() => {
          setEdit(false)
          setSomethingIsEditing(false)
        })
        .catch((err) => {
          setError(getError(err).message)
        })
        .finally(() => setSubmitting(false))
    }

    static onEditCancel() {
      setSomethingIsEditing(false)
      setEdit(false)
    }

    static startEditing() {
      setEdit(true)
      setSomethingIsEditing(true)
    }

    static startPredelete() {
      setPredelete(true)
      setSomethingIsEditing(true)
    }

    static stopPredelete() {
      setPredelete(false)
      setSomethingIsEditing(false)
    }
  }

  if (edit) {
    return (
      <FormikProvider value={formik}>
        <Form noValidate onSubmit={handleSubmit}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: 'grey.400',
              marginBottom: '5px',
            }}
          >
            <Box marginBottom={0.5}>
              <Box display="flex" gap="10px">
                <Box width="35%">
                  {Boolean(touched.form && errors.form) && (
                    <Typography color="error" fontSize="10px">
                      {touched.form && errors.form}
                    </Typography>
                  )}
                </Box>
                <Box width="65%">
                  {Boolean(touched.description && errors.description) && (
                    <Typography color="error" fontSize="10px">
                      {touched.description && errors.description}
                    </Typography>
                  )}
                </Box>
              </Box>

              <Box display="flex" gap="10px">
                <Box width="35%">
                  <Input
                    placeholder={t('word_form', {
                      ns: 'dictionaryPage',
                    })}
                    {...getFieldProps('form')}
                  />
                </Box>
                <Box width="65%">
                  <Input
                    fullWidth
                    placeholder={t('description')}
                    {...getFieldProps('description')}
                  />
                </Box>
              </Box>
            </Box>

            {error && !isSubmitting && <Alert severity="error">{error}</Alert>}

            <Box display="flex" justifyContent="flex-end">
              <LoadingButton
                type="submit"
                loading={isSubmitting}
                variant="text"
                size="small"
                sx={{ color: 'grey.400', padding: '1px' }}
              >
                {t('confirm')}
              </LoadingButton>

              <Button
                variant="text"
                size="small"
                onClick={Methods.onEditCancel}
                sx={{ color: 'grey.400' }}
              >
                {t('cancel')}
              </Button>
            </Box>
          </Box>
        </Form>
      </FormikProvider>
    )
  }

  return (
    <Box sx={{ marginBottom: '5px' }}>
      <Box display="flex" gap="10px" fontSize="14px">
        <Box width="35%">
          <Typography sx={{ overflowWrap: 'break-word', fontWeight: 600 }}>
            {wordForm.form}
          </Typography>
        </Box>
        <Box width="65%">
          {wordForm.description && (
            <Typography
              sx={{
                color: 'grey.800',
                overflowWrap: 'break-word',
              }}
            >
              - {wordForm.description}
            </Typography>
          )}
        </Box>
      </Box>

      <Box minHeight={20}>
        {!(somethingIsEditing && !predelete) && (
          <Box display="flex" justifyContent="flex-end">
            {!predelete ? (
              <>
                <Button
                  variant="text"
                  size="small"
                  onClick={Methods.startEditing}
                  sx={{ color: 'grey.400', fontSize: '12px' }}
                >
                  {t('edit')}
                </Button>
                <Button
                  variant="text"
                  size="small"
                  onClick={Methods.startPredelete}
                  sx={{ color: 'grey.400', fontSize: '12px' }}
                >
                  {t('delete')}
                </Button>
              </>
            ) : (
              <Box margin={1} display="flex" gap={1} alignItems="center">
                <Typography
                  fontWeight={600}
                  sx={{
                    fontSize: {
                      sm: '16px',
                      xs: '10px',
                    },
                  }}
                >
                  {t('are_you_sure')}
                </Typography>
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => {
                    onFormDelete(wordForm.id)
                    setSomethingIsEditing(false)
                  }}
                  sx={{ fontSize: '12px' }}
                >
                  {t('yes')}
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  onClick={Methods.stopPredelete}
                  sx={{ fontSize: '12px' }}
                >
                  {t('cancel')}
                </Button>
              </Box>
            )}
          </Box>
        )}
      </Box>
    </Box>
  )
}

interface IAddNewForm {
  onAddNewForm: (
    example: Pick<IWordForm, 'form' | 'description'>,
  ) => Promise<void>
  somethingIsEditing: boolean
  setSomethingIsEditing: (b: boolean) => void
}

export const AddNewForm = ({
  onAddNewForm,
  somethingIsEditing,
  setSomethingIsEditing,
}: IAddNewForm) => {
  const { t } = useTranslation(['dictionaryPage', 'common'])

  const [isAdding, setIsAdding] = useState(false)
  const [error, setError] = useState<string | null>(null)

  const Schema = Yup.object().shape({
    form: Yup.string().min(1).max(20).required(),
    description: Yup.string().max(35).optional(),
  })

  const formik = useFormik({
    initialValues: {
      form: '',
      description: '',
    },
    validationSchema: Schema,
    onSubmit: () => {
      onAdd()
    },
  })

  const {
    errors,
    touched,
    values,
    isSubmitting,
    setSubmitting,
    handleSubmit,
    getFieldProps,
    setValues,
  } = formik

  const onAdd = () => {
    onAddNewForm({
      form: values.form,
      description: values.description,
    })
      .then(() => {
        setIsAdding(false)
        setSomethingIsEditing(false)
        setValues({ form: '', description: '' })
      })
      .catch((err) => {
        setError(getError(err).message)
      })
      .finally(() => setSubmitting(false))
  }

  const startAdding = () => {
    setIsAdding(true)
    setSomethingIsEditing(true)
  }

  const stopAdding = () => {
    setIsAdding(false)
    setSomethingIsEditing(false)
    setValues({ form: '', description: '' })
  }

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <>
          {!(somethingIsEditing && !isAdding) && (
            <div>
              {!isAdding ? (
                <Box display="flex" justifyContent="center">
                  <Button variant="outlined" size="small" onClick={startAdding}>
                    {t('add_form')}
                  </Button>
                </Box>
              ) : (
                <Box>
                  <Box marginBottom={0.5}>
                    <Box display="flex" gap="10px">
                      <Box width="35%">
                        {Boolean(touched.form && errors.form) && (
                          <Typography color="error" fontSize="10px">
                            {touched.form && errors.form}
                          </Typography>
                        )}
                      </Box>
                      <Box width="65%">
                        {Boolean(touched.description && errors.description) && (
                          <Typography color="error" fontSize="10px">
                            {touched.description && errors.description}
                          </Typography>
                        )}
                      </Box>
                    </Box>

                    <Box display="flex" gap="10px">
                      <Box width="35%">
                        <Input
                          placeholder={t('word_form')}
                          {...getFieldProps('form')}
                        />
                      </Box>
                      <Box width="65%">
                        <Input
                          fullWidth
                          placeholder={t('description')}
                          {...getFieldProps('description')}
                        />
                      </Box>
                    </Box>
                  </Box>

                  {error && !isSubmitting && (
                    <Alert severity="error">{error}</Alert>
                  )}

                  <Box display="flex" justifyContent="flex-end">
                    <LoadingButton
                      type="submit"
                      loading={isSubmitting}
                      variant="text"
                      size="small"
                      sx={{ color: 'grey.400', padding: '1px' }}
                    >
                      {t('add')}
                    </LoadingButton>

                    <Button
                      variant="text"
                      size="small"
                      onClick={stopAdding}
                      sx={{ color: 'grey.400' }}
                    >
                      {t('cancel', {
                        ns: 'common',
                      })}
                    </Button>
                  </Box>
                </Box>
              )}
            </div>
          )}
        </>
      </Form>
    </FormikProvider>
  )
}
