import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Scrollbar from 'src/components/Scrollbar'
import { IDictionary } from 'src/types/Dictionary'
import { IWord } from 'src/types/Word'

import PublicDictionaryWordModal from './WordModal'
import PublicDictionaryWordRow from './WordRow'

interface IProps {
  words: IWord[] | null
  dictionary: IDictionary | null
}

const PublicDictionaryWordList = ({ words, dictionary }: IProps) => {
  const { t } = useTranslation(['publicDictionaryPage', 'common'])

  const [showModal, setShowModal] = useState<boolean>(false)
  const [openedWord, setOpenedWord] = useState<IWord | null>(null)

  const handleCloseTranscriptionModal = () => {
    setShowModal(false)
  }

  const handleOpenWordModal = (word: IWord) => {
    setOpenedWord(word)
    setShowModal(true)
  }

  const showTranscription = dictionary ? dictionary.showTranscription : true

  const headLabel = [
    { id: 'word', label: t('word', { ns: 'common' }), alignRight: false },
    {
      id: 'translation',
      label: t('translation', { ns: 'common' }),
      alignRight: false,
    },
    ...(showTranscription
      ? [
          {
            id: 'transcription',
            label: t('transcription', { ns: 'common' }),
            alignRight: true,
          },
        ]
      : []),
  ]

  return (
    <>
      <Scrollbar>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {headLabel.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    align={headCell.alignRight ? 'right' : 'left'}
                    style={{
                      width: headCell.id === 'transcription' ? '10%' : '45%',
                    }}
                  >
                    {headCell.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {words &&
                words.length > 0 &&
                words.map((i) => (
                  <PublicDictionaryWordRow
                    key={i.id}
                    item={i}
                    handleOpenWordModal={handleOpenWordModal}
                    showTranscription={showTranscription}
                  />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>

      {openedWord && (
        <PublicDictionaryWordModal
          isOpen={showModal}
          onClose={handleCloseTranscriptionModal}
          openedWord={openedWord}
          setOpenedWord={setOpenedWord}
        />
      )}
    </>
  )
}

export default PublicDictionaryWordList
