import SwapHorizIcon from '@mui/icons-material/SwapHoriz'
import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import IconButtonWrapper from 'src/components/buttons/IconButtonWrapper'
import StartButton from 'src/components/buttons/StartButton'
import DictionaryListSelect from 'src/components/widgets/DictionaryListSelect'
import DictionaryStore from 'src/mobx/dictionary'

interface IProps {
  onStart: () => void
  isTranslation: boolean
  onSwap: () => void
}

const RememberSetupScreen = ({ onStart, isTranslation, onSwap }: IProps) => {
  const { t } = useTranslation(['workoutPages'])

  return (
    <Box
      textAlign="center"
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <Typography color="lightgray" mb="20px">
        {t('choose_dictionary')}
      </Typography>

      <DictionaryListSelect />

      {DictionaryStore.selectedDictionary && (
        <Box display="flex" alignItems="center" marginTop="20px" gap={1}>
          <Box>
            {isTranslation
              ? DictionaryStore.selectedDictionary.fromLanguage.lang
              : DictionaryStore.selectedDictionary.toLanguage.lang}
          </Box>
          <IconButtonWrapper icon={SwapHorizIcon} onClick={onSwap} />
          <Box>
            {isTranslation
              ? DictionaryStore.selectedDictionary.toLanguage.lang
              : DictionaryStore.selectedDictionary.fromLanguage.lang}
          </Box>
        </Box>
      )}

      <StartButton
        onClick={onStart}
        label={t('start')}
        isDisabled={DictionaryStore.selectedDictionary === null}
      />
    </Box>
  )
}

export default RememberSetupScreen
