import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import DictionaryStore from 'src/mobx/dictionary'

import SelectWithFilter from '../inputs/SelectWithFilter'

interface IProps {
  label?: string
}

const DictionaryListSelect = observer(({ label }: IProps) => {
  const { t } = useTranslation(['common'])

  const dictionarySelectOptions = useMemo(
    () =>
      DictionaryStore.dictionaries &&
      DictionaryStore.dictionaries.map((item) => ({
        label: item.title,
        value: item.id,
      })),
    [DictionaryStore.dictionaries],
  )

  const onDictionaryChange = (dictionaryId: number) => {
    const dictionary =
      DictionaryStore.dictionaries &&
      DictionaryStore.dictionaries.find((item) => item.id === dictionaryId)
    if (dictionary) DictionaryStore.setSelectedDictionary(dictionary, true)
  }

  return (
    <>
      {DictionaryStore.selectedDictionary && dictionarySelectOptions && (
        <SelectWithFilter
          onChange={(id) => onDictionaryChange(id)}
          options={dictionarySelectOptions}
          value={DictionaryStore.selectedDictionary.id}
          label={label ?? t('dictionary')}
        />
      )}
    </>
  )
})

export default DictionaryListSelect
