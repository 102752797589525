import { ILanguage } from 'src/types/Language'

import { client } from '../axios'
import { ApiRoutes } from '../routes'

interface ILanguages {
  languages: ILanguage[]
}

export const getLanguages = async (): Promise<ILanguages> => {
  return (await client.get(ApiRoutes.getLanguages)).data
}
