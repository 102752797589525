import {
  Alert,
  Card,
  Container,
  Pagination,
  Table,
  TableContainer,
} from '@mui/material'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { updateWord } from 'src/api/requests/words'
import LoadingContainer from 'src/components/common/LoadingContainer'
import Scrollbar from 'src/components/Scrollbar'
import DictionaryStore from 'src/mobx/dictionary'
import WordModal from 'src/sections/dashboard/dictionary/WordModal'
import { IWord } from 'src/types/Word'
import { getError } from 'src/utils/getError'

import DictionaryWordList from './WordList'
import WordListHead from './WordListHead'
import WordListToolbar from './WordListToolbar'

export const ITEMS_PER_PAGE = 100

const WordsBlock = observer(() => {
  const { t } = useTranslation(['dictionaryPage', 'common'])

  const [selected, setSelected] = useState<number[]>([])
  const [showModal, setShowModal] = useState<boolean>(false)
  const [openedWord, setOpenedWord] = useState<IWord | null>(null)

  const showTranscription = DictionaryStore.selectedDictionary
    ? DictionaryStore.selectedDictionary.showTranscription
    : true

  const TABLE_HEAD = [
    { id: 'word', label: t('word', { ns: 'common' }), alignRight: false },
    {
      id: 'translation',
      label: t('translation', { ns: 'common' }),
      alignRight: false,
    },
    ...(showTranscription
      ? [
          {
            id: 'transcription',
            label: t('transcription', { ns: 'common' }),
            alignRight: false,
          },
        ]
      : []),
    { id: '' },
  ]

  const handleChangePage = (event: any, newPage: number) => {
    DictionaryStore.setWordListPage(newPage)

    if (DictionaryStore.selectedDictionary)
      DictionaryStore.getDictionaryWords(
        DictionaryStore.selectedDictionary.id,
        {
          page: newPage,
          filter: DictionaryStore.filterWord,
        },
      )
  }

  const pagesCount =
    DictionaryStore.totalWordCount &&
    Math.ceil(DictionaryStore.totalWordCount / ITEMS_PER_PAGE)

  const handleCloseTranscriptionModal = () => {
    setShowModal(false)
  }

  const handleOpenWordModal = (word: IWord) => {
    setOpenedWord(word)
    setShowModal(true)
  }

  const onSubmit = (
    editedWord: IWord,
    setError: (message: string) => void,
  ): Promise<void> => {
    return updateWord(editedWord.id, editedWord)
      .then((res) => {
        DictionaryStore.updateWord(res.word)
        setOpenedWord(res.word)
      })
      .catch((err) => {
        setError(getError(err).message)
      })
  }

  return (
    <Card style={{ minHeight: '800px' }}>
      <WordListToolbar selected={selected} setSelected={setSelected} />
      {openedWord && (
        <WordModal
          isOpen={showModal}
          onClose={handleCloseTranscriptionModal}
          openedWord={openedWord}
          setOpenedWord={setOpenedWord}
          onSubmit={onSubmit}
        />
      )}
      <Scrollbar>
        <TableContainer>
          {!DictionaryStore.wordLoading ? (
            <>
              {!!DictionaryStore.selectedDictionaryWords.length ||
              !!DictionaryStore.otherDictionaryWords.length ? (
                <Table>
                  <WordListHead
                    headLabel={TABLE_HEAD}
                    rowCount={
                      DictionaryStore.selectedDictionaryWords.length +
                      DictionaryStore.otherDictionaryWords.length
                    }
                    numSelected={selected.length}
                    setSelected={setSelected}
                  />
                  <DictionaryWordList
                    selected={selected}
                    setSelected={setSelected}
                    handleOpenWordModal={handleOpenWordModal}
                  />
                </Table>
              ) : (
                <AlertWordNotFound />
              )}
            </>
          ) : (
            <LoadingContainer />
          )}
        </TableContainer>
      </Scrollbar>

      {!!pagesCount && pagesCount > 1 && (
        <Pagination
          style={{
            padding: '10px',
            marginTop: '30px',
            display: 'flex',
            justifyContent: 'flex-end',
          }}
          count={pagesCount}
          page={DictionaryStore.wordListPage}
          onChange={handleChangePage}
        />
      )}
    </Card>
  )
})

export default WordsBlock

const AlertWordNotFound = () => {
  const { t } = useTranslation(['dictionaryPage', 'common'])

  return (
    <Container>
      <Alert severity="warning">
        {DictionaryStore.filterWord ? (
          <>
            {t('word_not_found')}
            <b>{t('add_word')}</b> {t('button_dot')}
          </>
        ) : (
          <>
            {t('no_words_1')}
            <b>{t('search', { ns: 'common' })}</b>
            {t('no_words_2')}
          </>
        )}
      </Alert>
    </Container>
  )
}
