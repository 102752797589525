import { makeAutoObservable } from 'mobx'
import { getDictionaries } from 'src/api/requests/dictionaries'
import {
  getDictionaryWords,
  IDictionaryWordsParams,
} from 'src/api/requests/words'
import { WordSortBy } from 'src/api/types/WordSortBy'
import { WordSortDirection } from 'src/api/types/WordSortDirection'
import { IApiError } from 'src/types/ApiError'
import { IDictionary } from 'src/types/Dictionary'
import { IWord } from 'src/types/Word'
import { getError } from 'src/utils/getError'

import { SELECTED_DICTIONARY_ID_STORE_KEY } from './../resources/localStorageKeys'

class DictionaryStore {
  dictionaries: IDictionary[] | null = null
  selectedDictionary: IDictionary | null = null
  dictionaryLoading = false
  dictionaryError: IApiError | null = null
  selectedDictionaryWords: IWord[] = []
  otherDictionaryWords: IWord[] = []
  totalWordCount: number | null = null
  wordLoading = false
  wordError: IApiError | null = null
  wordListPage = 1
  filterWord = ''
  sortBy: WordSortBy = WordSortBy.word
  sortDirection: WordSortDirection = WordSortDirection.ASC
  wordsPerPage = 100

  constructor() {
    makeAutoObservable(this)
  }

  // Promise Returns Selected Dictionary
  async getDictionaries(): Promise<IDictionary> {
    this.setDictionaryLoading(true)
    const savedSelectedDictionaryId = localStorage.getItem(
      SELECTED_DICTIONARY_ID_STORE_KEY,
    )
    this.setDictionaryError(null)

    return await getDictionaries()
      .then((res) => {
        this.setDictionaries(res.dictionaries)
        let selectedDictionary = res.dictionaries[0]

        if (savedSelectedDictionaryId) {
          const selectedDictionaryExist = res.dictionaries.find(
            (d) => +d.id === +savedSelectedDictionaryId,
          )

          if (selectedDictionaryExist)
            selectedDictionary = selectedDictionaryExist
        }

        this.setSelectedDictionary(selectedDictionary, true)
        return selectedDictionary
      })
      .catch((err) => {
        this.setDictionaryError(getError(err))
        return err
      })
      .finally(() => {
        this.setDictionaryLoading(false)
      })
  }

  setDictionaries(dictionaries: IDictionary[] | null) {
    this.dictionaries = dictionaries
  }

  setDictionaryError(value: IApiError | null) {
    this.dictionaryError = value
  }

  setSelectedDictionary(dictionary: IDictionary | null, updateWords?: boolean) {
    this.selectedDictionary = dictionary
    if (dictionary) {
      localStorage.setItem(
        SELECTED_DICTIONARY_ID_STORE_KEY,
        JSON.stringify(dictionary.id),
      )
      this.wordListPage = 1
      this.filterWord = ''
      if (updateWords) this.getDictionaryWords(dictionary.id)
    } else localStorage.removeItem(SELECTED_DICTIONARY_ID_STORE_KEY)
  }

  getDictionaryWords = async (
    dictionaryId?: number,
    params?: IDictionaryWordsParams,
  ): Promise<void> => {
    this.setWordLoading(true)
    this.wordError = null

    const dicId =
      dictionaryId || (this.selectedDictionary && this.selectedDictionary.id)

    if (dicId) {
      await getDictionaryWords(dicId, {
        filter: (params && params.filter) || this.filterWord,
        limit: (params && params.limit) || this.wordsPerPage,
        page: (params && params.page) || this.wordListPage,
        sortBy: (params && params.sortBy) || this.sortBy,
        sortDirection: (params && params.sortDirection) || this.sortDirection,
      })
        .then((res) => {
          this.setSelectedDictionaryWords(res.words, res.totalCount)
          this.setOtherDictionaryWords(res.otherDictionaryWords)
        })
        .catch((err) => {
          this.wordError = getError(err)
        })
        .finally(() => {
          this.setWordLoading(false)
        })
    } else {
      this.setDictionaryError({
        message: 'Dictionary is not choosen',
        code: '1000',
      })
    }
  }

  removeWordsById(ids: number[]) {
    if (this.selectedDictionaryWords) {
      const filteredWords = this.selectedDictionaryWords.filter(
        (item) => !ids.includes(item.id),
      )

      this.selectedDictionaryWords = [...filteredWords]
    }
    if (this.otherDictionaryWords) {
      const filteredWords = this.otherDictionaryWords.filter(
        (item) => !ids.includes(item.id),
      )

      this.otherDictionaryWords = [...filteredWords]
    }
  }

  updateSelectedDictionary(newDictionaryValues: IDictionary) {
    if (this.dictionaries && this.selectedDictionary) {
      const index = this.dictionaries.findIndex(
        (d) => d.id === newDictionaryValues.id,
      )

      if (index >= 0) {
        const newDictionary = {
          ...this.dictionaries[index],
          ...newDictionaryValues,
        }
        const newDictionaryList = [
          ...this.dictionaries.slice(0, index),
          newDictionary,
          ...this.dictionaries.slice(index + 1),
        ]

        this.setDictionaries(newDictionaryList)
        this.setSelectedDictionary(newDictionary)
      }
    }
  }

  removeDictionaryById(id: number) {
    if (this.dictionaries) {
      const filteredDictionaries = this.dictionaries.filter(
        (item) => item.id !== id,
      )

      if (filteredDictionaries.length > 0) {
        this.dictionaries = [...filteredDictionaries]
        this.setSelectedDictionary(filteredDictionaries[0], true)
      } else {
        this.dictionaries = null
        this.setSelectedDictionary(null)
      }
    }
  }

  setSelectedDictionaryWords(words: IWord[], totalWordCount?: number) {
    this.selectedDictionaryWords = words
    if (totalWordCount !== undefined) this.totalWordCount = totalWordCount
  }

  setOtherDictionaryWords(words: IWord[]) {
    this.otherDictionaryWords = words
  }

  addWord(word: IWord) {
    const wordList = this.selectedDictionaryWords
    this.selectedDictionaryWords = [word, ...(wordList ? wordList : [])]
  }

  getWordById(wordId: number): IWord | null {
    let item = this.selectedDictionaryWords.find((i) => i.id === wordId)

    if (!item && this.otherDictionaryWords) {
      item = this.otherDictionaryWords.find((i) => i.id === wordId)
    }

    return item || null
  }

  onChangeSortBy(s: WordSortBy) {
    this.setSortBy(s)
    this.getDictionaryWords(undefined, { sortBy: s })
  }

  toggleSortDirection() {
    const newDirection =
      this.sortDirection === WordSortDirection.ASC
        ? WordSortDirection.DESC
        : WordSortDirection.ASC

    this.setSortDirection(newDirection)
    this.getDictionaryWords(undefined, { sortDirection: newDirection })
  }

  setSortBy(s: WordSortBy) {
    this.sortBy = s
  }

  setSortDirection(d: WordSortDirection) {
    this.sortDirection = d
  }

  setDictionaryLoading(value: boolean) {
    this.dictionaryLoading = value
  }

  setWordLoading = (value: boolean) => {
    this.wordLoading = value
  }

  setWordListPage = (value: number) => {
    this.wordListPage = value
  }

  setFilterWord = (value: string) => {
    this.filterWord = value
  }

  updateWord(word: IWord) {
    if (this.selectedDictionaryWords) {
      const filteredWords = this.selectedDictionaryWords.map((item) =>
        item.id === word.id ? word : item,
      )

      this.selectedDictionaryWords = [...filteredWords]
    }
    if (this.otherDictionaryWords) {
      const filteredWords = this.otherDictionaryWords.map((item) =>
        item.id === word.id ? word : item,
      )

      this.otherDictionaryWords = [...filteredWords]
    }
  }
}

export default new DictionaryStore()
