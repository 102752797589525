import Page from 'src/components/Page'

const Settings = () => {
  return (
    <Page title="Settings">
      <div>Settings</div>
    </Page>
  )
}

export default Settings
