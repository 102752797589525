import { LoadingButton } from '@mui/lab'
import { Alert, Stack, TextField } from '@mui/material'
import { Form, FormikProvider, useFormik } from 'formik'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getError } from 'src/utils/getError'
import * as Yup from 'yup'

interface IProps {
  onSubmit: (message: string) => Promise<any>
  prefilledMessage?: string
}

const TextareaForm = ({ prefilledMessage, onSubmit }: IProps) => {
  const [error, setError] = useState<string | null>(null)

  const { t } = useTranslation(['common', 'error'])

  const Schema = Yup.object().shape({
    message: Yup.string().min(8).max(300).required(),
  })

  const formik = useFormik({
    initialValues: {
      message: prefilledMessage || '',
    },
    validationSchema: Schema,
    onSubmit: () => {
      onSubmit(values.message)
        .then(() => setError(null))
        .catch((err) => setError(getError(err).message))
        .finally(() => setSubmitting(false))
    },
  })

  const {
    errors,
    touched,
    values,
    isSubmitting,
    setSubmitting,
    handleSubmit,
    getFieldProps,
  } = formik

  return (
    <FormikProvider value={formik}>
      <Form
        style={{ minWidth: '150px' }}
        autoComplete="off"
        noValidate
        onSubmit={handleSubmit}
      >
        <Stack spacing={{ sm: 3, xs: 1 }} mt={2}>
          <TextField
            fullWidth
            multiline
            minRows={5}
            type="text"
            label={t('message')}
            error={Boolean(touched.message && errors.message)}
            helperText={touched.message && errors.message}
            {...getFieldProps('message')}
          />
        </Stack>

        {error && !isSubmitting && (
          <Alert sx={{ mt: { sm: 4, xs: 2 } }} severity="error">
            {error}
          </Alert>
        )}

        <LoadingButton
          sx={{ mt: { sm: 4, xs: 2 } }}
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
        >
          {t('confirm')}
        </LoadingButton>
      </Form>
    </FormikProvider>
  )
}

export default TextareaForm
