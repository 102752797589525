import { Alert, Box, Checkbox, FormControlLabel } from '@mui/material'
import React, { useState } from 'react'
import { getError } from 'src/utils/getError'

import LoadingContainer from '../common/LoadingContainer'

interface IProps {
  initalValue?: boolean
  onChange: (value: boolean) => Promise<void>
  label?: string
  component?: React.ReactNode
}

const CheckboxForm = ({ initalValue, onChange, label, component }: IProps) => {
  const [error, setError] = useState<string | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [checked, setChecked] = useState(initalValue || false)

  const onCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLoading(true)
    const checkedValue = event.target.checked
    setChecked(checkedValue)
    return onChange(checkedValue)
      .catch((err) => setError(getError(err).message))
      .finally(() => setLoading(false))
  }

  return (
    <Box>
      {!loading && (
        <Box>
          <FormControlLabel
            control={<Checkbox checked={checked} onChange={onCheckboxChange} />}
            label={label}
          />
        </Box>
      )}

      {checked && !loading && !error && component}
      {loading && <LoadingContainer size="medium" />}
      {error && !loading && <Alert severity="error">{error}</Alert>}
    </Box>
  )
}

export default CheckboxForm
