import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { LoadingButton } from '@mui/lab'
import {
  Alert,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
} from '@mui/material'
import { Form, FormikProvider, useFormik } from 'formik'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'

interface IProps {
  onSubmit: (
    password: string,
    setError: (message: string) => void,
  ) => Promise<void>
}

const PasswordRestorationForm = ({ onSubmit }: IProps) => {
  const [error, setError] = useState<string | null>(null)
  const [showFirstPassword, setShowFirstPassword] = useState<boolean>(false)
  const [showSecondPassword, setShowSecondPassword] = useState<boolean>(false)

  const { t } = useTranslation(['finishRestorePasswordPage', 'error'])

  const EmailSchema = Yup.object().shape({
    password: Yup.string()
      .min(8, 'Minimum 8 characters required')
      .required(t('password_required', { ns: 'error' })),
    passwordConfirm: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Password must be same!')
      .required(t('password_required', { ns: 'error' })),
  })

  const formik = useFormik({
    initialValues: {
      password: '',
      passwordConfirm: '',
    },
    validationSchema: EmailSchema,
    onSubmit: () => {
      onSubmit(values.password, setError).finally(() => setSubmitting(false))
    },
  })

  const {
    errors,
    touched,
    values,
    isSubmitting,
    setSubmitting,
    handleSubmit,
    getFieldProps,
  } = formik

  const handleShowFirstPassword = () => {
    setShowFirstPassword((show) => !show)
  }

  const handleShowSecondPassword = () => {
    setShowSecondPassword((show) => !show)
  }

  return (
    <FormikProvider value={formik}>
      <Form
        style={{ minWidth: '350px' }}
        autoComplete="off"
        noValidate
        onSubmit={handleSubmit}
      >
        <Stack spacing={3}>
          <TextField
            fullWidth
            autoComplete="current-password"
            type={showFirstPassword ? 'text' : 'password'}
            label={t('password')}
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowFirstPassword} edge="end">
                    {showFirstPassword ? (
                      <VisibilityIcon />
                    ) : (
                      <VisibilityOffIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showSecondPassword ? 'text' : 'password'}
            label={t('passwordConfirm')}
            {...getFieldProps('passwordConfirm')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowSecondPassword} edge="end">
                    {showSecondPassword ? (
                      <VisibilityIcon />
                    ) : (
                      <VisibilityOffIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={Boolean(touched.passwordConfirm && errors.passwordConfirm)}
            helperText={touched.passwordConfirm && errors.passwordConfirm}
          />
        </Stack>

        {error && !isSubmitting && (
          <Alert sx={{ mt: 1 }} severity="error">
            {error}
          </Alert>
        )}

        <LoadingButton
          sx={{ mt: 2 }}
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
        >
          {t('next')}
        </LoadingButton>
      </Form>
    </FormikProvider>
  )
}

export default PasswordRestorationForm
