import MenuIcon from '@mui/icons-material/Menu'
import { AppBar, Box, IconButton, Stack, Toolbar } from '@mui/material'
import { alpha, styled } from '@mui/material/styles'
import { observer } from 'mobx-react-lite'
import UIStore from 'src/mobx/ui'

import AccountPopover from './AccountPopover'
import LanguagePopover from './LanguagePopover'

const DRAWER_WIDTH = 280
const APPBAR_MOBILE = 64
const APPBAR_DESKTOP = 92

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`,
  },
}))

const ToolbarStyle: any = styled(Toolbar)(
  ({ theme, isMinimize = false }: any) => ({
    minHeight: isMinimize ? 0 : APPBAR_MOBILE,
    ...(isMinimize && { alignItems: 'flex-start' }),
    [theme.breakpoints.up('sm')]: {
      ...(isMinimize && { minHeight: 0 }),
    },
    [theme.breakpoints.up('md')]: {
      ...(isMinimize && { minHeight: 0 }),
    },
    [theme.breakpoints.up('lg')]: {
      minHeight: isMinimize ? 0 : APPBAR_DESKTOP,
      padding: theme.spacing(0, 2),
    },
  }),
)

const MenuStack: any = styled(Stack)(
  ({ theme, isMinimize = false, left }: any) => ({
    ...(isMinimize && {
      position: 'absolute',
      ...(left ? { left: 0 } : { right: 0 }),
      top: theme.spacing(1),
      backgroundColor: alpha(theme.palette.background.default, 1),
      borderRadius: '5px',
    }),
  }),
)

interface IProps {
  onOpenSidebar: () => void
}

const DashboardNavbar = observer(({ onOpenSidebar }: IProps) => {
  return (
    <RootStyle>
      <ToolbarStyle isMinimize={UIStore.isHeaderMinimized}>
        <Box position="relative">
          <MenuStack isMinimize={UIStore.isHeaderMinimized} left>
            <IconButton
              onClick={onOpenSidebar}
              sx={{ color: 'text.primary', display: { lg: 'none' } }}
            >
              <MenuIcon fontSize="medium" />
            </IconButton>
          </MenuStack>
        </Box>

        <Box sx={{ flexGrow: 1 }} />

        <Box position="relative">
          <MenuStack
            direction="row"
            spacing={{ xs: 0.5, sm: 1.5 }}
            isMinimize={UIStore.isHeaderMinimized}
          >
            <LanguagePopover />
            <AccountPopover />
          </MenuStack>
        </Box>
      </ToolbarStyle>
    </RootStyle>
  )
})

export default DashboardNavbar
