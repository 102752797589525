import { Alert, Container, Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import LoadingContainer from 'src/components/common/LoadingContainer'
import Page from 'src/components/Page'
import DictionaryStore from 'src/mobx/dictionary'
import { DictionaryBar } from 'src/sections/dashboard/dictionary'
import { WordsBlock } from 'src/sections/dashboard/dictionary'

const Dictionary = observer(() => {
  const { t } = useTranslation(['dictionaryPage', 'common'])

  return (
    <Page title={t('pageTitle')}>
      {!DictionaryStore.dictionaryLoading ? (
        <Container>
          {DictionaryStore.dictionaryError ? (
            <Typography>{t('dictionaries_not_downloaded')}</Typography>
          ) : (
            <>
              <DictionaryBar />
              {DictionaryStore.dictionaries?.length ? (
                <WordsBlock />
              ) : (
                <Alert severity="warning">
                  {t('no_dictionaries_1')}
                  <b>{t('addDictionary')}</b>
                  {t('button_dot')}
                </Alert>
              )}
            </>
          )}
        </Container>
      ) : (
        <LoadingContainer />
      )}
    </Page>
  )
})

export default Dictionary
