export const PATH_DASHBOARD = 'dashboard'
export const PATH_DICTIONARY = 'dictionary'
export const PATH_WORKOUT = 'workouts'
export const PATH_I_REMEMBER = 'i-remember'
export const PATH_LETTER_HAMMER = 'letter-hammer'
export const PATH_USER = 'user'
export const PATH_PROFILE = 'profile'
export const PATH_SETTINGS = 'settings'
export const PATH_LOGIN = 'login'
export const PATH_REGISTER = 'register'
export const PATH_NOTFOUND = '404'
export const PATH_ACTIVATE = 'activate'
export const PATH_RESTORE_PASSWORD = 'password-restoration'

export const ROUTE_LOGIN = `/${PATH_LOGIN}`

export const ROUTE_DICTIONARY = `/${PATH_DASHBOARD}/${PATH_DICTIONARY}`
export const ROUTE_WORKOUT = `/${PATH_DASHBOARD}/${PATH_WORKOUT}`

export const ROUTE_PROFILE = `/${PATH_DASHBOARD}/${PATH_USER}/${PATH_PROFILE}`
export const ROUTE_SETTINGS = `/${PATH_DASHBOARD}/${PATH_USER}/${PATH_SETTINGS}`

export const ROUTE_PUBLIC_DICTIONARY = `/public/${PATH_DICTIONARY}`
