import FormatBoldIcon from '@mui/icons-material/FormatBold'
import { LoadingButton } from '@mui/lab'
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Stack,
  styled,
  TextareaAutosize,
  Tooltip,
  Typography,
} from '@mui/material'
import { Form, FormikProvider, useFormik } from 'formik'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IWordUsageExample } from 'src/types/WordUsageExample'
import { getError } from 'src/utils/getError'
import * as Yup from 'yup'

const Textarea = styled(TextareaAutosize)(() => ({
  padding: '7px',
  minHeight: '35px',
  height: '50px',
  width: '100%',
  resize: 'none',
  border: '1px solid gray',
  borderRadius: '5px',
}))

type TWordExampleFields = Pick<
  IWordUsageExample,
  'sentence' | 'translation' | 'isBold'
>

interface IExampleItemProps {
  example: IWordUsageExample
  onExampleEdit: (example: IWordUsageExample) => Promise<void>
  onExampleDelete: (example: number) => void
  somethingIsEditing: boolean
  setSomethingIsEditing: (b: boolean) => void
}

export const WordUsageExampleItem = ({
  example,
  onExampleEdit,
  onExampleDelete,
  somethingIsEditing,
  setSomethingIsEditing,
}: IExampleItemProps) => {
  const { t } = useTranslation(['common'])

  const [edit, setEdit] = useState(false)
  const [predelete, setPredelete] = useState(false)

  const onEditSubmit = (values: TWordExampleFields) => {
    return onExampleEdit({
      ...example,
      ...values,
    }).then(() => {
      setEdit(false)
      setSomethingIsEditing(false)
    })
  }

  const onEditCancel = () => {
    setSomethingIsEditing(false)
    setEdit(false)
  }

  if (edit) {
    return (
      <WordExampleForm
        example={example}
        onSubmit={onEditSubmit}
        onCancel={onEditCancel}
      />
    )
  }

  const startEditing = () => {
    setEdit(true)
    setSomethingIsEditing(true)
  }

  const startPredelete = () => {
    setPredelete(true)
    setSomethingIsEditing(true)
  }

  const stopPredelete = () => {
    setPredelete(false)
    setSomethingIsEditing(false)
  }

  return (
    <Box
      sx={{ borderBottom: 1, borderColor: 'grey.400', marginBottom: '10px' }}
    >
      <Box>
        <Typography
          sx={{
            fontSize: '16px',
            overflowWrap: 'break-word',
            fontWeight: example.isBold ? 600 : 400,
          }}
        >
          {example.sentence}
        </Typography>
        {example.translation && (
          <Typography
            sx={{
              fontSize: '13px',
              color: 'grey.400',
              overflowWrap: 'break-word',
              fontWeight: example.isBold ? 600 : 400,
            }}
          >
            {example.translation}
          </Typography>
        )}
      </Box>

      <Box minHeight={20}>
        {!(somethingIsEditing && !predelete) && (
          <Box display="flex" justifyContent="flex-end">
            {!predelete ? (
              <>
                <Button
                  variant="text"
                  size="small"
                  onClick={startEditing}
                  sx={{ color: 'grey.400', fontSize: '12px' }}
                >
                  {t('edit')}
                </Button>
                <Button
                  variant="text"
                  size="small"
                  onClick={startPredelete}
                  sx={{ color: 'grey.400', fontSize: '12px' }}
                >
                  {t('delete')}
                </Button>
              </>
            ) : (
              <Box margin={1} display="flex" gap={1} alignItems="center">
                <Typography
                  fontWeight={600}
                  sx={{
                    fontSize: {
                      sm: '16px',
                      xs: '10px',
                    },
                  }}
                >
                  {t('are_you_sure')}
                </Typography>
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => {
                    onExampleDelete(example.id)
                    setSomethingIsEditing(false)
                  }}
                  sx={{ fontSize: '12px' }}
                >
                  {t('yes')}
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  onClick={stopPredelete}
                  sx={{ fontSize: '12px' }}
                >
                  {t('cancel')}
                </Button>
              </Box>
            )}
          </Box>
        )}
      </Box>
    </Box>
  )
}

interface IAddNewExample {
  onAddNewExample: (example: TWordExampleFields) => Promise<void>
  somethingIsEditing: boolean
  setSomethingIsEditing: (b: boolean) => void
}

export const AddNewExample = ({
  onAddNewExample,
  somethingIsEditing,
  setSomethingIsEditing,
}: IAddNewExample) => {
  const { t } = useTranslation(['dictionaryPage'])

  const [isAdding, setIsAdding] = useState(false)

  const onSubmit = (values: TWordExampleFields): Promise<void> => {
    return onAddNewExample(values).then(() => {
      setIsAdding(false)
      setSomethingIsEditing(false)
    })
  }

  const onStartAdding = () => {
    setIsAdding(true)
    setSomethingIsEditing(true)
  }

  const onCancel = () => {
    setIsAdding(false)
    setSomethingIsEditing(false)
  }

  return (
    <>
      {!(somethingIsEditing && !isAdding) && (
        <>
          {!isAdding ? (
            <Box display="flex" justifyContent="center">
              <Button variant="outlined" size="small" onClick={onStartAdding}>
                {t('add_example')}
              </Button>
            </Box>
          ) : (
            <WordExampleForm onSubmit={onSubmit} onCancel={onCancel} />
          )}
        </>
      )}
    </>
  )
}

interface IWordExampleForm {
  example?: IWordUsageExample
  onSubmit: (val: TWordExampleFields) => Promise<void>
  onCancel: () => void
}

const WordExampleForm = ({ example, onSubmit, onCancel }: IWordExampleForm) => {
  const { t } = useTranslation(['dictionaryPage'])

  const [error, setError] = useState<string | null>(null)

  const defaultValues = {
    sentence: '',
    translation: '',
    isBold: false,
  }

  const Schema = Yup.object().shape({
    sentence: Yup.string().min(3).max(150).required(),
    translation: Yup.string().min(3).max(150).optional(),
  })

  const formik = useFormik({
    initialValues: example
      ? {
          sentence: example.sentence,
          translation: example.translation || '',
          isBold: example.isBold || false,
        }
      : defaultValues,
    validationSchema: Schema,
    onSubmit: (val) => {
      onSubmit(val)
        .then(() => {
          setValues(defaultValues)
        })
        .catch((err) => setError(getError(err).message))
        .finally(() => setSubmitting(false))
    },
  })

  const {
    errors,
    touched,
    values,
    isSubmitting,
    setSubmitting,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    setValues,
  } = formik

  const handleCancel = () => {
    setValues(defaultValues)
    onCancel()
  }

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Box>
          <Box marginBottom={0.5}>
            <Stack marginBottom={1}>
              {Boolean(touched.sentence && errors.sentence) && (
                <Typography color="error" fontSize="10px">
                  {touched.sentence && errors.sentence}
                </Typography>
              )}
              <Textarea
                placeholder={t('word_usage_example', {
                  ns: 'dictionaryPage',
                })}
                {...getFieldProps('sentence')}
              />
            </Stack>
            <Stack>
              {Boolean(touched.translation && errors.translation) && (
                <Typography color="error" fontSize="10px">
                  {touched.translation && errors.translation}
                </Typography>
              )}
              <Textarea
                placeholder={t('translation')}
                {...getFieldProps('translation')}
              />
            </Stack>
          </Box>

          {error && !isSubmitting && <Alert severity="error">{error}</Alert>}

          <Box display="flex" justifyContent="flex-end">
            <Box>
              <Tooltip title="Make text Bold" placement="top">
                <Checkbox
                  checked={values.isBold}
                  onChange={() => setFieldValue('isBold', !values.isBold)}
                  icon={<FormatBoldIcon color="disabled" />}
                  checkedIcon={<FormatBoldIcon color="action" />}
                />
              </Tooltip>
            </Box>
            <Box display="flex" justifyContent="flex-end">
              <LoadingButton
                type="submit"
                loading={isSubmitting}
                variant="text"
                size="small"
                sx={{ color: 'grey.400', padding: '1px' }}
              >
                Ok
              </LoadingButton>

              <Button
                variant="text"
                size="small"
                onClick={handleCancel}
                sx={{ color: 'grey.400' }}
              >
                {t('cancel')}
              </Button>
            </Box>
          </Box>
        </Box>
      </Form>
    </FormikProvider>
  )
}
