import { Alert, Container, Pagination } from '@mui/material'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getPublicDictionary } from 'src/api/requests/dictionaries'
import LoadingContainer from 'src/components/common/LoadingContainer'
import Page from 'src/components/Page'
import Header from 'src/sections/publicDictionary/Header'
import Title from 'src/sections/publicDictionary/Title'
import PublicDictionaryWordList from 'src/sections/publicDictionary/WordList'
import { IDictionary } from 'src/types/Dictionary'
import { IWord } from 'src/types/Word'
import { getError } from 'src/utils/getError'

export const ITEMS_PER_PUBLIC_DICTIONARY_PAGE = 100

const PublicDictionary = () => {
  const { dictionaryId } = useParams()

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)

  const [publicDictionary, setPublicDictionary] = useState<IDictionary | null>(
    null,
  )
  const [publicDictionaryWords, setPublicDictionaryWords] = useState<
    IWord[] | null
  >(null)
  const [wordsCount, setWordsCount] = useState<number | null>(null)
  const [page, setPage] = useState<number>(1)

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage)
  }

  const pagesCount =
    wordsCount && Math.ceil(wordsCount / ITEMS_PER_PUBLIC_DICTIONARY_PAGE)

  useEffect(() => {
    if (dictionaryId && Number.isInteger(+dictionaryId)) {
      setLoading(true)
      getPublicDictionary(+dictionaryId, { page })
        .then((res) => {
          setPublicDictionary(res.publicDictionary)
          setPublicDictionaryWords(res.publicDictionaryWords)
          setWordsCount(res.totalWordCount)
          setError(null)
        })
        .catch((err) => setError(getError(err).message))
        .finally(() => setLoading(false))
    }
  }, [dictionaryId, page])

  return (
    <Page title={publicDictionary?.title || 'Public Dictionary'}>
      <Header />

      {loading ? (
        <LoadingContainer />
      ) : (
        <Container>
          {error && <Alert color="error">{error}</Alert>}

          <Title title={publicDictionary ? publicDictionary.title : ''} />

          <PublicDictionaryWordList
            dictionary={publicDictionary}
            words={publicDictionaryWords}
          />

          {!!pagesCount && pagesCount > 1 && (
            <Pagination
              style={{
                padding: '10px',
                marginTop: '30px',
                display: 'flex',
                justifyContent: 'flex-end',
              }}
              count={pagesCount}
              page={page}
              onChange={handleChangePage}
            />
          )}
        </Container>
      )}
    </Page>
  )
}

export default PublicDictionary
